export class ShippingYardCustomItem {

  constructor(
    public jobLoadId: number,
    public customItemId: number,
    public itemDescription: string,
    public quantity: number,
    public loadedOnTrailerDateTime: Date | null,
    public checkOutTimestamp: Date,
    public isTrailerLoaded: boolean,
    public description: string,
    public isExpanded: boolean,
    public weight: number | null,
  ) {  }
}
