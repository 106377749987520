<div id="projectDetails" class="sticky">
  <ng-container>
    <div class="card card-body bg-light overflow-x-auto">
      <!--<div class="card-body">-->
      <div class="row flex-nowrap ">
        <div class="col-auto">
          <button *ngIf="showToggle" class="btn btn-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseJobInfo" aria-expanded="true" aria-controls="collapseWidthExample">
            Job Info
          </button>
          <input id="jobNumber" style="width:100px; display: inline-block" type="number" pattern="[0-9]*" class="form-control mx-2 "
                 (keydown.enter)="searchJob(jobNumber)" placeholder="JOB" [(ngModel)]="jobNumber" required />
          <button id="jobSearchButton" (click)="searchJob(jobNumber)" class="btn btn-sm btn-primary" type="submit">
            Search
          </button>
        </div>
        <div class=" col-auto " *ngIf="projectTitle && !isFieldPlanDisplayed">
          <p class="fs-5 fw-bold">
            Project: <a href="{{projectUrl}}" target="_blank">{{projectNumber}}</a> - {{projectTitle}}
          </p>
        </div>
        <div *ngIf="canRecalculateUnmobilized" class="col-auto">
          <!--(canRecalculateUnmobilized && projectTitle && !isFieldPlanDisplayed) ||-->
          <button type="button" class="btn btn-sm btn-primary" (click)="openModal(confirmDaysRecalculate)"
                  [disabled]="fullyMobilized">
            Recalculate Unmobilized Days
          </button>
        </div>
        <div *ngIf="canRecalculateJob" class="col-auto">
          <!-- canRecalculateJob && projectTitle && !isFieldPlanDisplayed -->
          <button class="btn btn-sm btn-primary" (click)="openModal(confirmJobRecalculate)"
                  [disabled]="this.fullyShipped">
            Recalculate Entire Job
          </button>
        </div>
      </div>
      <div class="collapse show" id="collapseJobInfo" *ngIf="projectSearchResults$ | async as projectSearchResults">
        <div class="row flex-nowrap align-items-center">
          <div class="col-auto">
            Bid Stretch Rate: {{projectSearchResults.project?.bidStretchRate | number: '1.2-2' }}
          </div>
          <div class="col-auto">
            Plan Setting Rate: {{projectSearchResults.project?.planSettingRate | number: '1.2-2' }}
          </div>

          <div class="align-items-center col-auto">
            <label class="me-1" for="ffCheck"> Fabcon Friday: </label>
            <input id="ffCheck" type="checkbox" [(ngModel)]="this.fabconFridayProject"
                   (change)="fabconFridayChange()" [disabled]="partiallyMobilized" />
          </div>
          <div class="col-auto">
            <label for="maxLoadWeight" class="form-label me-1">Max Load Weight:</label>
            <input id="maxLoadWeight" style="width:100px; display: inline-block" type="number"
                   class="form-control me-2" name="maxLoadWeight" [(ngModel)]="maxWeight" [disabled]="!canEditProject">
            <button *ngIf="canEditProject" class="btn btn-sm btn-primary"
                    (click)=openModal(confirmApply)>
              Apply
            </button>
          </div>
          <div class="col-auto">
            Erector: {{projectSearchResults.project?.erector}}
          </div>
          <a class="col-auto btn btn-link" *ngIf="projectSearchResults.projectDateExceptions.length > 0"
             href="{{wipLink + projectSearchResults.projectDateExceptions[0].job}}" target="_blank">WIP Report</a>
          <a class="col-auto btn btn-link" href="{{sumByWidthsByWallLink}}" target="_blank">
            Sum of Widths by
            Wall
          </a>
          <div class="col-auto">
            <button type="button" class="btn btn-link" data-bs-toggle="modal"
                    data-bs-target="#fieldPlanPanelTypesModal">
              Panel Types
            </button>
          </div>
          <a class="col-auto btn btn-link" href="../../assets/TrailerStrappingDiagram.pdf" target="_blank">
            Trailer
            Strapping Diagram
          </a>
        </div>
        <div class="row flex-nowrap  align-items-center">
          <div class="col-auto">
            Proj Mgr: {{projectSearchResults.project?.projectManager }}
          </div>
          <div class="col-auto">
            Fld Sup: {{projectSearchResults.project?.fieldSup }}
          </div>
          <div class="col-auto">
            Fld Foreman: {{projectSearchResults.project?.fieldForeman }}
          </div>
          <div class="col-auto">
            Load Count: {{this.loadCount}}
          </div>
          <div class="col-auto">
            Max Pnl Weight: {{projectSearchResults.project?.maxPanelWeight }}
          </div>
          <div *ngIf="projectSearchResults.project.paymentStatus" class="">
            <label class="text-danger">Payment Status: Balance Due Prior to Delivery</label>
          </div>
          <div *ngIf="multiplePlants" class="col-auto">
            <label>{{plantSummary}}</label>
          </div>
          <div class="col-auto">
            <button type="button" class="btn btn-link" data-bs-toggle="modal"
                    data-bs-target="#fieldPlanColorKeyModal">
              Color Key
            </button>
          </div>
          <div class="col-auto">
            <button type="button" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#userPrferencesModal">
              User Preferences
            </button>
          </div>
          <div class="col-auto">
            Available Cranes:
            <select style="width:auto" class="form-select-sm" id="availableCranes" name="availableCranes"
                    (change)="openModal(confirmCraneChange)" [(ngModel)]="this.availableCranes"
                    [disabled]="!canEditProject">
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
          </div>
          <div class="col-auto" *ngIf="isEditable">
            <button *ngIf="canEditProject" class="btn btn-sm btn-primary me-2"
                    (click)="openModal(confirmApproveJob, fieldPlanEmailType.sendFieldPlanApprovedEmail)"
                    [disabled]="fullyMobilized">
              Approve Sequence
            </button>
            <button class="btn btn-sm btn-primary"
                    (click)="openModal(sendAlert, fieldPlanEmailType.sendFieldPlanModificationEmail);"
                    *ngIf="showModifiedAlert">
              Send Notification
            </button>
          </div>
        </div>
        <div class="col-auto" *ngIf="canEditLoads">
          Reorder loads using:
          <select style="width:auto" class="form-select-sm" id="reorderSelect" name="reorderSelect"
                  (change)="reOrderLoadsChanged()" [(ngModel)]="sortableType" [disabled]="!canEditLoads">
            <option value="1">Panel</option>
            <option value="2">Load</option>
          </select>
        </div>
      </div>
      <!--</div>-->
      <!--</div>-->
    </div>
  </ng-container>
</div>

<!--Field Plan Grid Panels-->
<div id="fieldPlanDetails" class="container-fluid scrollContainer" cdkScrollable>
  <!-- [ngStyle]="{ height: resizeWindow()}" -->
  <div class="row">
    <!--fp-panel-height-->
    <div class="row">
      <div *ngIf="noScheduleFound">
        <h3>
          Sequence has not been entered for Job
        </h3>
      </div>

      <h3 *ngIf="fullyMobilized">
        Job is fully mobilized and ready for shipment
      </h3>
    </div>

    <div style="width:600px" cdkDropListGroup>
      <div *ngFor="let fieldPlanCrane of this.fieldPlanCranes as FieldPlanCraneSummaryInfo;">
        <div class="row flex-nowrap">
          <h3 class="mt-2" *ngIf="fieldPlanCranes.length > 1">Crane {{fieldPlanCrane.craneID === 1 ? 'A' : 'B'}}</h3>
          <input type="hidden" class="crane-number" value="${fieldPlanCrane.CraneID}" />
        </div>
        <div class="row flex-nowrap">
          <div class="mr-2 {{fieldPlanSummary.backgroundColor}}"
               *ngFor="let fieldPlanSummary of fieldPlanCrane.fieldPlanSummaries as FieldPlanSummaryInfo; index as i">
            <div>
              <app-field-plan-detail (triggerPageReload)="reloadSearchResults()" [maxWeight]="maxWeight"
                                     id="app-field-plan-detail-{{fieldPlanSummary.shipDate}}" [fullyMobilized]="fullyMobilized"
                                     [partiallyMobilized]="partiallyMobilized" [sortableType]="sortableType"
                                     [craneCount]="this.availableCranes" [jobNumber]="jobNumber" [fieldPlanSummary]="fieldPlanSummary"
                                     [userPrefs]="userPrefs" [craneId]="fieldPlanCrane.craneID" [firstShipDate]="firstShipDate"
                                     [panelTypes]="panelTypes$" (showSendAlertModal)="showSendAlertModal(sendAlert)"
                                     (emailType)="setEmailType($event)"></app-field-plan-detail>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Field Plan Color Key Modal -->
<div class="modal fade" id="fieldPlanColorKeyModal" tabindex="-1" aria-labelledby="fieldPlanColorKeyModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="fieldPlanColorKeyModalLabel">Color Key</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <table class="table table-striped">
          <tbody>
            <tr>
              <td style="border: 2px solid black; background-color: #0000FF">&nbsp;</td>
              <td>Blue - Piece is marked as [Sent] in QA database</td>
            </tr>
            <tr>
              <td style="border: 2px solid black; background-color: #00FF00">&nbsp;</td>
              <td>Green - Piece is marked [OK as is], [repaired] or [no tag] and has an inspection signoff in QA</td>
            </tr>
            <tr>
              <td style="border: 2px solid black; background-color: #7AE8FF">&nbsp;</td>
              <td>Light Blue - Piece has been shipped and field tagged</td>
            </tr>
            <tr>
              <td style="border: 2px solid black; background-color: #FBC235">&nbsp;</td>
              <td>Orange - Piece has a patch tag action of [Field Tag] or [sent requiring repair]</td>
            </tr>
            <tr>
              <td style="border: 2px solid black; background-color: #FF0000">&nbsp;</td>
              <td>Red - Piece has not been cast. (QA database Date > Current Date)</td>
            </tr>
            <tr>
              <td style="border: 2px solid black; background-color: #58595B">&nbsp;</td>
              <td>Grey - Panel has not been stamped by Drafting team</td>
            </tr>
            <tr>
              <td style="border: 2px solid black; background-color: #FF02CC">&nbsp;</td>
              <td>Pink - Piece is scheduled to be cast but hasn't been stripped yet</td>
            </tr>
            <tr>
              <td style="border: 2px solid black; background-color: #FFFF00">&nbsp;</td>
              <td>Yellow - Piece has been cast but there is inspection signoff for the cast</td>
            </tr>
            <tr>
              <td style="border: 2px solid black; background-color: white">&nbsp;</td>
              <td>White - None of the other criteria are met. This should never be the case</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- End Field Plan Color Key Modal -->
<!-- Field Plan Panel Types Modal -->
<div class="modal fade" id="fieldPlanPanelTypesModal" tabindex="-1" aria-labelledby="fieldPlanPanelTypesModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="fieldPlanPanelTypesModalLabel">Panel Types</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body d-flex justify-content-center">
        <div class="panelTypes">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Panel Type</th>
                <th>Description</th>
                <th>Time To Set</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let panelType of (panelTypes$ | async) as panelTypesResults">
                <td>{{panelType.panelTypeCode}}</td>
                <td>{{panelType.typeDescription}}</td>
                <td>{{panelType.timeToSet}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- End Field Plan Panel Types Modal -->
<!--UserPreferemceModal-->
<app-user-preference [userPrefs]="userPrefs" (userPrefsUpdated)="onPrefsUpdated($event)"></app-user-preference>

<!-- Begin Confirm Apply Modal -->
<ng-template #confirmApply class="modal-dialog">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirmation</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Update project maximum ship weight?</p>
    <div *ngIf="this.partiallyMobilized">
      <h5>This field plan has already been accepted. Modifications may affect shipping schedule?</h5>
      <button type="button" class="btn btn-sm btn-primary"
              (click)="applyWeightChange(false); bsModalRef?.hide(); openModal(sendAlert)">
        OK & Finished Editing
      </button>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" data-bs-dismiss="modal"
            (click)="resetMaxWeight(); bsModalRef?.hide()">
      Cancel
    </button>
    <button type="button" class="btn btn-sm btn-primary" (click)="applyWeightChange(true); bsModalRef?.hide();">OK</button>
  </div>
</ng-template>
<!-- End Confirm Apply Modal -->
<!-- Begin Confirm Crane Count Modal -->
<ng-template #confirmCraneChange class="modal-dialog-centered modal-lg">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirmation</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Modify available crane count to {{this.availableCranes}}?</p>
    <div *ngIf="this.partiallyMobilized">
      <p>This field plan has already been accepted. Modifications may affect shipping schedule?</p>
      <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal"
              (click)="availableCranesChanged(); bsModalRef?.hide(); openModal(sendAlert)">
        OK & Finished Editing
      </button>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" data-bs-dismiss="modal"
            (click)="resetAvailableCranes(); bsModalRef?.hide()">
      Cancel
    </button>
    <button type="button" class="btn btn-sm btn-primary" (click)="availableCranesChanged();  bsModalRef?.hide();">OK</button>
  </div>
</ng-template>
<!-- End Confirm Crane Count Modal -->
<!-- Begin Send Alert Modal -->
<ng-template #sendAlert class="modal-dialog-centered">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Modification Alert</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Please enter any comments describing what changed</p>
    <textarea id="alertText" class="col-12" [(ngModel)]="alertComments" rows="6"></textarea>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" data-bs-dismiss="modal"
            (click)="bsModalRef?.hide(); showSendAlertButton(); reloadSearchResults();">
      Don't Send
    </button>
    <button type="button" class="btn btn-sm btn-primary" (click)="setEmailType(fieldPlanEmailType.sendFieldPlanModificationEmail); sendEmail(); bsModalRef?.hide();">Send Alert</button>
    <!-- reloadSearchResults(); -->
  </div>
</ng-template>
<!-- End Confirm Crane Count Modal -->
<!-- Begin Recalculate Unmobilized Days Modal -->
<ng-template #confirmDaysRecalculate class="modal-dialog-centered">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirmation</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Recalculate Job {{this.jobNumber}}? This may modify information for ALL days that have not been mobilized</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="bsModalRef?.hide()">Cancel</button>
    <button type="button" class="btn btn-sm btn-primary" (click)="recalculateUnmobilized();  bsModalRef?.hide();">OK</button>
  </div>
</ng-template>
<!-- End Confirm Recalculate Unmobilized Days Modal -->
<!-- Begin Confirm Recalculate Job Modal -->
<ng-template #confirmJobRecalculate class="modal-dialog-centered">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirmation</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Recalculate Job {{this.jobNumber}}? This may modify information for ALL days that have not started shipping</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="bsModalRef?.hide()">Cancel</button>
    <button type="button" class="btn btn-sm btn-primary" (click)="recalculateJob();  bsModalRef?.hide();">OK</button>
  </div>
</ng-template>
<!-- End Confirm Recalculate Job Modal -->
<!-- Begin Confirm Approve Job Modal -->
<ng-template #confirmApproveJob class="modal-dialog-centered">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirmation</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Approve sequence for Job {{this.jobNumber}}?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="bsModalRef?.hide()">Cancel</button>
    <button type="button" class="btn btn-sm btn-primary" (click)="sendEmail();  bsModalRef?.hide();">OK</button>
  </div>
</ng-template>
<!-- End Confirm Approve Job Modal -->
