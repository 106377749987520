import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ToastService } from './toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
  // animations: [
  //   trigger('openClose', [
  //     state(
  //       'closed',
  //       style({
  //         visibility: 'hidden',
  //         right: '-400px',
  //       })
  //     ),
  //     state(
  //       'open',
  //       style({
  //         right: '40px',
  //       })
  //     ),
  //     transition('open <=> closed', [animate('0.5s ease-in-out')]),
  //   ]),
  // ],
})
export class ToastComponent implements OnInit {
  @ViewChild('element', { static: false }) progressBar: ElementRef = {} as ElementRef;
  progressInterval: string | number | NodeJS.Timer | undefined;

  constructor(public toastService: ToastService) {
    this.toastService.open.subscribe((data) => {
      if (data.show) {
        this.displayTimer();
      }
    });
  }

  ngOnInit() {
    this.toastService.open.subscribe((data) => {
      if(data.show){
        this.displayTimer();
      }
    })
  }

  displayTimer() {
    this.toastService.data.styleClasses = this.toastService.data.type === 0 ? 'toast fade show text-bg-danger' : this.toastService.data.type === 2 ? 'toast fade show text-bg-info' : 'toast fade show text-bg-success';

    let timerId = setInterval(() => {}, 5000);
    setTimeout(() => { clearInterval(timerId); this.toastService.data.styleClasses = 'toast fade'; }, 3000);
  }
}
