import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ShippingSetup } from './shipping-setup.model';
import { ShippingYardPanel } from '../shipping-yard/shipping-yard-panel.model';
import { ErrorResponse } from '../error-handling/error-response.model';
import { ShippingOfficeCheckInCraneCrew } from '../shipping-office/shipping-office-checkin/shipping-office-checkin-crane-crew.model';
import { ShippingSetupCustomItem } from './shipping-setup-custom-item.model';
import { ShippingYardCustomItem } from '../shipping-yard/shipping-yard-custom-item.model';

const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable({
  providedIn: 'root'
})

export class ShippingSetupService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }

  getSetupLoads(companyIds: number[], jobNumber: string): Observable<ShippingSetup[]> {
    try {
      return this.http.get<ShippingSetup[]>(`${this.baseUrl}/shippingsetup/setups`,{ params: {companyIds: companyIds, job: jobNumber} });
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  updateShipSetupTrailerSequence(job: number, firstPanelMark: string, firstPanelTrailerSeq: number, secondPanelMark: string, secondPanelTrailerSeq: number) {
    try {
      return this.http.post<ErrorResponse>(`${this.baseUrl}/shippingsetup/updateShipSetupTrailerSequence?job=${job}&&firstPanelMark=${firstPanelMark}&&firstPanelTrailerSeq=${firstPanelTrailerSeq}&&secondPanelMark=${secondPanelMark}&&secondPanelTrailerSeq=${secondPanelTrailerSeq}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  getCraneCrews(companyId: number): Observable<ShippingOfficeCheckInCraneCrew[]> {
    try {
      return this.http.get<ShippingOfficeCheckInCraneCrew[]>(`${this.baseUrl}/shippingoffice/craneCrews?companyId=${companyId}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  getCustomItems(): Observable<ShippingSetupCustomItem[]> {
    try {
      return this.http.get<ShippingSetupCustomItem[]>(`${this.baseUrl}/shippingsetup/customitems`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  updateShipSetupLoadItems(jobLoadId: number, shippingSetupCustomItems: ShippingYardCustomItem[]) {
    try {
      return this.http.post<ErrorResponse>(`${this.baseUrl}/shippingsetup/updateshipsetuploaditems?jobLoadId=${jobLoadId}`, shippingSetupCustomItems, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  updateShipSetupPreload(shippingSetup: ShippingSetup) {
    try {
      return this.http.post<ErrorResponse>(`${this.baseUrl}/shippingsetup/updateshipsetuppreload`, shippingSetup, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  public getLoadListReport(job: string, loadNum: string) {
    try {
      return this.http.get(`${this.baseUrl}/shippingsetup/loadListReport?job=${job}&&loadNum=${loadNum}`, {
        observe: 'response', responseType: 'blob'
      });
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }
}
