
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, inject, signal } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Auth } from './auth-model';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { User } from './user-model';
import { CompanyPermission } from './company-permission-model ';

const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn: boolean = false;
  userRole: string = '';
  currentUserSig = signal<User | undefined | null>(undefined);

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private route: ActivatedRoute, private router: Router) {
    this.baseUrl = environment.backend.baseURL;
  }

  async login(userLogin: Auth, useDefaultRoute: boolean) {

    let storedAuthenticatedUser = localStorage.getItem('authenticatedUser');
    if (storedAuthenticatedUser) {
      localStorage.removeItem('authenticatedUser');
    }

    this.http.post<User>(`${this.baseUrl}/login/userlogin`, userLogin, options).subscribe({
      next: (authenticatedUser) => {
        let storedAuthenticatedUser = localStorage.getItem('authenticatedUser');

        if (storedAuthenticatedUser !== null) {
          let localUser: User = JSON.parse(storedAuthenticatedUser);
          authenticatedUser.companyId = localUser.companyId;
        }
        else if (authenticatedUser.companyId == 0) {
          authenticatedUser.companyId = environment.defaultCompanyId;
        }

        this.retrieveCompanyPermissions(authenticatedUser, useDefaultRoute);

        this.isLoggedIn = true;
      },
      error: (error) => {
        // Handle error cases
        console.error(error);

        localStorage.setItem('authenticatedUserError', JSON.stringify(error.error));
        this.router.navigate(['/login'])
      }
    });
  }

  isUserLoggedIn(): boolean {
    let storedAuthenticatedUser = localStorage.getItem('authenticatedUser');
    if (storedAuthenticatedUser) {

      return true;
    }

    return false;
  }

  logout() {
    localStorage.removeItem('authenticatedUser');
    this.currentUserSig.set(null);
    this.router.navigate(['/login'])
  }

  setLocalStorage() {
    localStorage.setItem('authenticatedUser', JSON.stringify(this.currentUserSig()));
  }

  hasRole(requestedRole: string): boolean {
    return this.currentUserSig()?.roles.includes(requestedRole)!;
  }

  hasPermission(requestedPermission: string): boolean {
    //console.log('this.currentUserSig()?.permissions', requestedPermission, this.currentUserSig()?.permissions);
    return this.currentUserSig()?.permissions.some(permission => permission.permissionName == requestedPermission)!;
  }

  retrieveCompanyPermissions(authenticatedUser: User, useDefaultRoute: boolean) {
    this.http.post<CompanyPermission[]>(`${this.baseUrl}/login/companyPermissions?companyId=${authenticatedUser.companyId}`, options).subscribe({
      next: (companyPermissions: CompanyPermission[]) => {
        authenticatedUser.companyPermissions = companyPermissions;
        localStorage.setItem('authenticatedUser', JSON.stringify(authenticatedUser));

        this.resetCompanySpecificPermissions(authenticatedUser)
        this.setRoute(useDefaultRoute);

        localStorage.setItem('authenticatedUser', JSON.stringify(authenticatedUser));
               
        this.currentUserSig.set(authenticatedUser);
      },
      error: (error) => {
        // Handle error cases
        console.error(error);
      }
    });
  }

  resetCompanySpecificPermissions(authenticatedUser: User) {
    authenticatedUser.hasMarkNumReassignPermission = this.hasCompanyPermission(authenticatedUser, 'MarkNumReassign')
    authenticatedUser.hasLoadAllPermission = this.hasCompanyPermission(authenticatedUser, 'LoadAll')
    this.currentUserSig.set(authenticatedUser);    
  }

  hasCompanyPermission(authenticatedUser: User, permissionName: string): boolean {
    let permissionId = authenticatedUser.permissions.find(x => x.permissionName == permissionName)?.permissionId;
    if (permissionId == null) {
      return false;
    }
    else {
      const hasPermission = authenticatedUser.companyPermissions.find(cp => cp.companyId === authenticatedUser.companyId && cp.permissionId === permissionId);
      return hasPermission !== undefined;
    }
  }

  setRoute(useDefaultRoute: boolean) {
    let returnUrl: ActivatedRouteSnapshot["queryParams"] = this.route.snapshot.queryParams['returnUrl'];

    if (returnUrl !== undefined && returnUrl.includes('/field-plan') && returnUrl.includes('job')) {
      let navigateUrl: string[] = returnUrl.split(';');
      this.router.navigate([navigateUrl[0] + '/' + navigateUrl[1].substring(navigateUrl[1].lastIndexOf('=') + 1)]);
    }
    else if (useDefaultRoute) { this.router.navigate(['/field-plan']); }
  }  
}
