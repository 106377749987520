import { ApplicationAdminService } from './../application-admin/application-admin.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit, TemplateRef, inject, signal } from '@angular/core';
import { Shipping3plBolService } from './shipping3pl-bol.service';
import { Shipping3plBol } from './shipping3pl-bol.model';
import { environment } from 'src/environments/environment';
import { Ship3plBOLShipInstructions } from './ship3pl-bol-ship-instructions.model';
import { Ship3plBOLLoadNumberInfo } from './ship3pl-bol-load-number-info.model';
import { Ship3plBOLLoadWindow } from './ship3pl-bol-load-window.model';
import { ErrorResponse } from '../error-handling/error-response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService, toastTypes } from '../toast/toast.service';
import { Ship3plBOLShipUpdateInstructions } from './ship3pl-bol-ship-update-instructions.model';
import { formatDate } from '@angular/common';
import { Log } from '../application-admin/log.model';
import { AuthService } from '../authentication/auth.service';
import { Shipping3plS3plService } from '../shipping3pl-s3pl/shipping3pl-s3pl.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { LoadingModalComponent } from '../controls/loading-modal/loading-modal.component';
import { User } from '../authentication/user-model';

const wait5s = () => {
  return new Promise<void>(resolve => {
    setTimeout(() => resolve(), 5000)
  })
}

@Component({
  selector: 'app-shipping3pl-bol',
  templateUrl: './shipping3pl-bol.component.html',
  styleUrl: './shipping3pl-bol.component.css',
  changeDetection: ChangeDetectionStrategy.Default
})

export class Shipping3plBolComponent implements OnInit {
  shipping3plBolService: Shipping3plBolService = inject(Shipping3plBolService);
  applicationAdminService: ApplicationAdminService = inject(ApplicationAdminService);
  shipping3plBols: Shipping3plBol[] = [];
  enteredJob = '';
  selectAllJobs: boolean = false;
  deliveryInformation: Ship3plBOLShipInstructions = new Ship3plBOLShipInstructions(0, '', '', '', '', '', '', '');
  loadNumbers: Ship3plBOLLoadNumberInfo[] = [];
  loadWindows: Ship3plBOLLoadWindow[] = [];
  hasSelectedBols: boolean = false;
  selectedShipDay: number = 0;
  hasPerformedSearch: boolean = false;
  authService = inject(AuthService);
  isLoading: boolean = false;
  order: string = "deliveryDate_Display";
  reverse: boolean = false;
  shipping3plS3plService: Shipping3plS3plService = inject(Shipping3plS3plService);
  isPastedFromExcel: boolean = false;
  isEditable: boolean = false;
  shipping3plBolsCopy: Shipping3plBol[] = [];

  shipDays = [
    {
      id: 1,
      text: '0',
      value: 0
    },
    {
      id: 2,
      text: '1',
      value: 1
    },
    {
      id: 3,
      text: '2',
      value: 2
    }];

  countries = [
    {
      id: 1,
      text: 'USA',
      value: 'USA'
    },
    {
      id: 2,
      text: 'Canada',
      value: 'Canada'
    }];

  bsModalRef?: BsModalRef;
  isTyping: boolean = false;
  doYourStuff: any = null;
  timeout: any;

  constructor(@Inject(LOCALE_ID) private locale: string, private toast: ToastService, private modalService: BsModalService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.enteredJob = this.shipping3plS3plService.currentJobNumber();

    if (this.enteredJob.length > 0) {
      this.onSearchBols();
    }

    let defaultLoadWindow = new Ship3plBOLLoadWindow(0, 0, '');
    this.loadWindows.push(defaultLoadWindow);

    let defaultLoadNumber = new Ship3plBOLLoadNumberInfo(0, 0, '', '000', new Date(), '', '', '', 0, '');
    this.loadNumbers.push(defaultLoadNumber);
  }

  public invalidSearch(): boolean {
    if (this.enteredJob == undefined || this.enteredJob.length == 0 || this.authService.currentUserSig()!.companyId == 0 || this.isEditable) {
      return true;
    }

    return false;
  }

  sleep(ms: number | undefined) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  onSearchBols(): void {

    this.openSeachModal('Loading Shipping 3PL Bol Setup...');

    this.isLoading = true;

    this.shipping3plS3plService.setJobNumber(this.enteredJob);

    try {
      this.sleep(500).then(() => {
        let userCompanyIds: number[] = [];


        let storedAuthenticatedUser = localStorage.getItem('authenticatedUser');

        if (storedAuthenticatedUser !== null) {
          let localUser: User = JSON.parse(storedAuthenticatedUser);
          if (this.authService.currentUserSig()!.companyCode.split(',').length == 1) {
            userCompanyIds.push(this.authService.currentUserSig()!.companyId)
          }
          else if (this.authService.currentUserSig()!.companyCode.split(',').length > 1) {
            localUser.userCompanies.forEach((company) => {
              userCompanyIds.push(company.companyId);
            });
          }
        }

        this.hasSelectedBols = false;

        this.shipping3plBolService.get3PlBols(userCompanyIds, this.enteredJob.toString()).subscribe(result => {
          let shipping3plBolsResult: Shipping3plBol[] = result;

          this.hasPerformedSearch = true;

          if (result.length > 0) {
            this.selectAllJobs = false;
            this.loadWindows = [];
            this.loadWindows.push(...shipping3plBolsResult[0].ship3PlBOLLoadWindows);
            this.deliveryInformation = shipping3plBolsResult[0].ship3PlBOLShipInstructions ?? new Ship3plBOLShipInstructions(Number(shipping3plBolsResult[0].job), '', '', '', '', '', '', '');

            shipping3plBolsResult.forEach((currentBol, i) => {
              currentBol.isChecked = false;
              currentBol.isEditable = false;

              this.loadNumbers = [];
              this.loadNumbers.push(...currentBol.ship3PlBOLLoadNumberInfos);

              let loadNumDisplay = currentBol.ship3PlBOLLoadNumberInfos.find(x => x.jobLoadId == currentBol.jobLoadID);
              currentBol.loadNum_Display = currentBol.jobLoadID == null ? '' : loadNumDisplay?.loadNum!;

              const date = new Date(currentBol.deliveryDate);
              const options: Intl.DateTimeFormatOptions = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              };

              const formattedDeliveryDateDisplay: string = new Intl.DateTimeFormat("en-US", options).format(date);
              currentBol.deliveryDate_Display = formattedDeliveryDateDisplay;

              let currentShip3PlBOLLoadNumberInfos = this.calculateLoads(currentBol.deliveryDate);
              currentBol.ship3PlBOLLoadNumberInfos = currentShip3PlBOLLoadNumberInfos;
            });
          }
          this.shipping3plBols = shipping3plBolsResult;
          this.shipping3plBolsCopy = this.shipping3plBols.map(i => Object.assign({}, i));
          this.isLoading = false;
          this.closeSearchModal();

        }, error => console.error(error));
      });
    } catch (error) {
      this.isLoading = false;
      this.closeSearchModal();
      console.log(error)
    }

  }

  openSeachModal(message: string) {
    const initialState: ModalOptions = {
      initialState: {
        message: message
      }
    };
    this.bsModalRef = this.modalService.show(LoadingModalComponent, Object.assign(initialState, { class: 'modal-lg modal-dialog-centered' }));
  }

  closeSearchModal() {
    this.modalService.hide();
  }

  async modelChangeBol() {

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.updateBol();
    }, 2000);
  }


  updateBol() {

    this.openSeachModal('Saving Shipping 3PL Bol Changes...');

    this.sleep(500).then(() => {

      let updateBol: Shipping3plBol[] = [];
      let updateEditedBols: Shipping3plBol[] = this.shipping3plBols;

      updateEditedBols.forEach((updatedBol) => {
        if (updatedBol.isEditable) {
          let checkMultipleLoadNumbers: Shipping3plBol[] = updateEditedBols.filter(x => x.loadNum_Display == updatedBol?.loadNum_Display &&
            x.crane == updatedBol?.crane);

          if (checkMultipleLoadNumbers.length == 2) {
            let alreadyAssignedBol: Shipping3plBol = checkMultipleLoadNumbers.filter(x => x.jobLoadID != updatedBol?.jobLoadID)[0]
            if (alreadyAssignedBol !== undefined) {
              this.openBolAlertModal(alreadyAssignedBol);
              return;
            }
          }

          if (updatedBol.expectedPickupDate_Display !== undefined) {

            if (updatedBol.expectedPickupDate_Display !== "" && updatedBol.expectedPickupDate_Display !== null) {
              const date = new Date(updatedBol.expectedPickupDate_Display);
              const formatter = new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              });

              const dateParts = formatter.formatToParts(date);

              const formattedExpectedPickupDate: string = `${dateParts[0].value}-${dateParts[2].value}-${dateParts[4].value}`;
              const formattedPickupDate: string = `${dateParts[4].value}-${dateParts[0].value}-${dateParts[2].value}`;

              updatedBol.expectedPickupDate = formattedPickupDate;
              updatedBol.expectedPickupDate_Display = formattedExpectedPickupDate;
            }
          }
          else {
            updatedBol.expectedPickupDate_Display = '';
            updatedBol.expectedPickupDate = null;
          }

          if (updatedBol.loadWindowID == 0) { updatedBol.loadWindowID = null }

          if (updatedBol?.loadNum_Display == '' || updatedBol?.loadNum_Display == null) {
            updatedBol.loadNum = null;
            updatedBol.jobLoadID = null;
          }
          else {
            updatedBol.loadNum = updatedBol?.loadNum_Display;
            updatedBol.jobLoadID = updatedBol.ship3PlBOLLoadNumberInfos.find(x => x.loadNum == updatedBol?.loadNum_Display)?.jobLoadId!;
          }

          updatedBol.ship3PlBOLLoadNumberInfos = [];
          updateBol.push(updatedBol);

          if (updatedBol !== undefined) {
            this.addUpdateLogMessage(updatedBol);
          }
        }
      })

      this.shipping3plBolService.update3plShipBolData(updateBol).subscribe((response: ErrorResponse) => {

        updateBol.forEach(currentBol => {
          currentBol.ship3PlBOLLoadNumberInfos = this.calculateLoads(currentBol.deliveryDate);
          this.modelInstructionsChange(currentBol.jobLoadBOLID);
        })

        this.isEditable = false;
        this.closeSearchModal();
        this.onSearchBols();
      });

    }, (error: HttpErrorResponse) => {
      console.log('error', error)
      this.closeSearchModal();
      this.toast.initiate({
        title: error.error.name,
        content: error.error.message,
        type: toastTypes.error,
      });
    });
  }

  modelInstructionsChange(jobLoadBolId: any) {

    let updateBol: Ship3plBOLShipUpdateInstructions[] = [];
    let updatedBol = this.shipping3plBols.find(x => x.jobLoadBOLID == jobLoadBolId);

    if (updatedBol !== undefined) {

      let update3plShipInstructions: Ship3plBOLShipUpdateInstructions = new Ship3plBOLShipUpdateInstructions(updatedBol.jobLoadBOLID, updatedBol.job, updatedBol.instructions!);

      updateBol.push(update3plShipInstructions);

      this.shipping3plBolService.update3plShipInstructions(updateBol).subscribe((response: ErrorResponse) => {
        this.toast.initiate({
          title: response.name,
          content: response.message,
          type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
        });

      }, (error: HttpErrorResponse) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      });
    }

  }

  setSelectedBol(selectedJobLoadId: number) {
    //Zero is for Select All Checkbox
    if (selectedJobLoadId == -1) {
      this.shipping3plBols.forEach((currentBol) => {
        currentBol.isChecked = !this.selectAllJobs;
      });
    } else {
      let selectedBol = this.shipping3plBols.find(x => x.jobLoadBOLID == selectedJobLoadId);

      if (selectedBol !== undefined) {
        selectedBol.isChecked = !selectedBol?.isChecked;
      }
    }

    let anySelectedRows: Shipping3plBol[] = this.shipping3plBols.filter(x => x.isChecked == true);

    this.hasSelectedBols = anySelectedRows!.length > 0 ? true : false;
  }

  applyInstructionsToSelected(specialInstructions: string) {

    this.isEditable = true;

    let selectedShip3plBols: Shipping3plBol[] = this.shipping3plBols.filter(x => x.isChecked == true);

    selectedShip3plBols.forEach((bol) => {
      bol.instructions = specialInstructions;
      bol.isEditable = true;

    });

  }

  saveDeliveryInfo() {

    this.shipping3plBolService.update3plShipDeliveryInformation(this.deliveryInformation).subscribe((response: ErrorResponse) => {
      this.toast.initiate({
        title: response.name,
        content: response.message,
        type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
      });

    }, (error: HttpErrorResponse) => {
      this.toast.initiate({
        title: error.error.name,
        content: error.error.message,
        type: toastTypes.error,
      });
    });
  }

  saveExpectedShipDates() {

    let updatedExpectedPickupDates: Shipping3plBol[] = this.shipping3plBols.filter(x => x.isChecked == true);

    updatedExpectedPickupDates.forEach((newDeliveryDate) => {
      let deliveryDate: Date = new Date(newDeliveryDate.deliveryDate!);
      let adjustedDay: number = deliveryDate.getDay() == 1 && this.selectedShipDay > 0 ? 3 : (deliveryDate.getDay() == 2 && this.selectedShipDay == 2) ? 4 : this.selectedShipDay;

      deliveryDate.setDate(deliveryDate.getDate() - adjustedDay);

      const date = new Date(deliveryDate);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      };

      const formattedExpectedPickupDate: string = new Intl.DateTimeFormat("en-US", options).format(date);

      newDeliveryDate.expectedPickupDate = formattedExpectedPickupDate;
      newDeliveryDate.expectedPickupDate_Display = formattedExpectedPickupDate;


      newDeliveryDate.isEditable = true;
    });

    this.isEditable = true;
  }

  validateAddress(): boolean {
    if (this.deliveryInformation.address1.length == 0 || this.deliveryInformation.city.length == 0 || this.deliveryInformation.state.length == 0
      || this.deliveryInformation.zip.length == 0 || this.deliveryInformation.country.length == 0) {
      return false;
    }

    return true;
  }

  openBolAlertModal(shippingBol: Shipping3plBol) {
    const modalDiv = document.getElementById('bolAlertModal');

    if (modalDiv != null) {
      modalDiv.style.display = 'block';
      const element: HTMLElement = document.getElementById('errorMessage') as HTMLElement;
      element.innerHTML = `BOL ${shippingBol.bol} already assigned to load ${shippingBol.loadNum_Display}`
    }
  }

  closeBolAlertModal() {
    const modalDiv = document.getElementById('bolAlertModal');

    if (modalDiv != null) {
      modalDiv.style.display = 'none';
    }
  }

  addUpdateLogMessage(shipping3plBol: Shipping3plBol) {
    let updateLog = new Log(null, null, 'Set3plBOLData ', new Date(), this.authService.currentUserSig()!.userName, []);

    //I am not sure what to do with the keys yet
    updateLog.keys.push(...Object.keys(shipping3plBol));

    updateLog.logMessage += shipping3plBol.trailer + ' ' + shipping3plBol.trailerSize + ' ' + shipping3plBol.loadingType;

    this.applicationAdminService.addUpdateLogMessage(updateLog).subscribe((response: ErrorResponse) => {
    }, (error: HttpErrorResponse) => {
      console.log(error);
    });
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  clearJobNumber() {
    this.shipping3plS3plService.setJobNumber('');
  }

  calculateLoads(deliveryDate: string) {

    let result = new Array();
    let alternateCrane = _.find(this.loadNumbers, x => x.crane == 2);
    for (let load of this.loadNumbers) {
      let loadDate = load.deliveryDate;

      // If we are multi-crane job, include crane in descriptor - else ignore
      load.loadDescriptor = (alternateCrane == null ? load.loadNum : load.loadDescriptor);

      if (formatDate(loadDate, 'MM/dd/y', this.locale) == formatDate(deliveryDate, 'MM/dd/y', this.locale)) {
        result.push(load);
      }
    }

    result.splice(0, 0, { Load: '', JobLoadID: null });

    return result;
  }

  public onPaste(event: any, shippingBol: Shipping3plBol) {

    event.preventDefault();
    this.isPastedFromExcel = true;
    let pastedData = event.clipboardData.getData("text/plain");
    let pastedRowData: string[] = pastedData.split('\r\n');
    let pastedShipping3plBols: Shipping3plBol[] = [];
    let bolIndex = this.shipping3plBols.findIndex(x => x.jobLoadBOLID == shippingBol.jobLoadBOLID);

    if (pastedRowData.length == 1) {
      //This is to paste one field in a row.
      switch (event.target.name) {
        case "shippingBol":
          this.shipping3plBols[bolIndex].bol = pastedRowData[0];
          break;
        case "carrierName":
          this.shipping3plBols[bolIndex].carrierName = pastedRowData[0];
          break;
        case "trailerSize":
          this.shipping3plBols[bolIndex].trailerSize = Number(pastedRowData[0]);
          break;
        case "trailer":
          this.shipping3plBols[bolIndex].trailer = pastedRowData[0];
          break;
        case "loadingType":
          this.shipping3plBols[bolIndex].loadingType = pastedRowData[0];
          break;
        case "instructions":
          this.shipping3plBols[bolIndex].instructions = pastedRowData[0];
          break;
        default:
          break;
      }

      this.shipping3plBols[bolIndex].isEditable = true;
      pastedShipping3plBols.push(this.shipping3plBols[bolIndex]);
    }
    else {
      pastedRowData.some((rowData: string) => {
        if (bolIndex < this.shipping3plBols.length) {
          let currentRowData = rowData.split('\t');
          this.shipping3plBols[bolIndex].bol = currentRowData[0]?.length == 0 || currentRowData[0] == undefined ? this.shipping3plBols[bolIndex].bol : currentRowData[0];
          this.shipping3plBols[bolIndex].carrierName = currentRowData[1]?.length == 0 || currentRowData[1] == undefined ? this.shipping3plBols[bolIndex].carrierName : currentRowData[1];
          this.shipping3plBols[bolIndex].trailerSize = currentRowData[2]?.length == 0 || currentRowData[2] == undefined ? this.shipping3plBols[bolIndex].trailerSize : Number(currentRowData[2]);
          this.shipping3plBols[bolIndex].trailer = currentRowData[3]?.length == 0 || currentRowData[3] == undefined ? this.shipping3plBols[bolIndex].trailer : currentRowData[3];
          this.shipping3plBols[bolIndex].loadingType = currentRowData[4]?.length == 0 || currentRowData[4] == undefined ? this.shipping3plBols[bolIndex].loadingType : currentRowData[4];
          this.shipping3plBols[bolIndex].instructions = currentRowData[5]?.length == 0 || currentRowData[5] == undefined ? this.shipping3plBols[bolIndex].instructions : currentRowData[5];

          this.shipping3plBols[bolIndex].isEditable = true;

          pastedShipping3plBols.push(this.shipping3plBols[bolIndex]);
          bolIndex++;
        }
      });
    }

    this.isEditable = true;
  }

  editBol(shippingBol: Shipping3plBol) {

    shippingBol.isEditable = true;
    this.isEditable = true;
  }

  editBolWithLoadNumber(shippingBol: Shipping3plBol) {

    //need to refresh this data with the updated data from the selected Load #
    if (shippingBol.loadNum_Display != undefined && shippingBol.loadNum_Display?.length > 0) {
      let selectedLoad: Ship3plBOLLoadNumberInfo = shippingBol.ship3PlBOLLoadNumberInfos.find(x => x.loadNum == shippingBol.loadNum_Display)!;

      if (shippingBol.carrierName.length == 0) { shippingBol.carrierName = selectedLoad.carrierName; }
      console.log('shippingBol.trailer0', shippingBol.trailer, shippingBol.trailer !== null)

      if (selectedLoad.trailer !== null && selectedLoad.trailer.length > 0) {
          shippingBol.trailer = selectedLoad.trailer;
      }

      if (shippingBol.trailerSize == null || shippingBol.trailerSize == 0) { shippingBol.trailerSize = selectedLoad.trailerSize; }
      if (shippingBol.loadingType == null || shippingBol.loadingType.length == 0) { shippingBol.loadingType = selectedLoad.loadingType; }
    }

    shippingBol.isEditable = true;
    this.isEditable = true;
  }

  cancelEdit() {
    this.isEditable = false;
    this.shipping3plBols = this.shipping3plBolsCopy.map(i => Object.assign({}, i));

    this.cdRef.detectChanges();

    this.bsModalRef?.hide();
  }

  showCancelModal(template: TemplateRef<void>) {
    this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered' });
  };

}
