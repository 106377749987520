<!-- Begin Assign Trailer Modal -->
<div class="modal fade modal-md" id="assignTrailerModal" tabindex="-1" aria-labelledby="addAssignTrailerLabel"
     aria-hidden="true" bsModal #modal="bs-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="assignTrailerLabel"
            *ngIf="!authService.currentUserSig()?.hasMarkNumReassignPermission">
          Assign a Trailer?
        </h1>
        <h1 class="modal-title fs-5" id="assignTrailerLabel"
            *ngIf="authService.currentUserSig()?.hasMarkNumReassignPermission">
          Assign a Trailer for Mark
          {{loadPanelMarkNumber}}
        </h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form #form="ngForm" class="modal-body needs-validation" novalidate (ngSubmit)="saveLoadTrailer()">
        <div class="container">
          <div class="row mb-2">
            <div class="col-md-5">
              <label class="form-label" for="trailerSize">Trailer Size</label>
              <input type="number" pattern="[0-9]*" class="form-control" autocomplete="off" [(ngModel)]="selectedTrailerSize" [ngModelOptions]="{standalone: true}" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="form-label mt-2" for="trailerNumber">Trailer Number</label>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-5">
              <select class="form-select" name="trailerNumber" [(ngModel)]="selectedTrailerId">
                <option [ngValue]="null">-Choose Trailer-</option>
                <option *ngFor="let trailer of yardTrailers" [ngValue]="trailer['trailerId']">
                  {{trailer['trailerName']}}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <input id="trailerNumber" type="text" placeholder="Trailer Number" class="form-control"
                     [(ngModel)]="selectedTrailerNumber" [ngModelOptions]="{standalone: true}" autocomplete="off" />
            </div>
          </div>
          <div *ngIf="hasTrailerErrorMessage" class="row col-md-8 alert alert-danger" role="alert">
            Please provide a trailer number.
          </div>
          <div class="row mb-2">
            <div class="col-md-5 mt-2">
              <label class="form-label">Loading Type</label>
              <input type="text" class="form-control" autocomplete="off" [(ngModel)]="selectedLoadingType" [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="col-md-6" *ngIf="authService.currentUserSig()?.hasMarkNumReassignPermission">
              <label class="form-label mt-2">Replace Mark Number with</label>
              <select class="form-select" name="markNumber" [(ngModel)]="selectedMarkNumber">
                <option [ngValue]="null">-Choose Mark-</option>
                <option *ngFor="let mark of yardMarkNumberReplacements" [ngValue]="mark.markNumber">
                  {{mark.markNumber}}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-5">
              <label class="form-label mt-2">Carrier</label>
              <input id="carrier" type="text" placeholder="Carrier" class="form-control"
                     [(ngModel)]="selectedCarrierName" [ngModelOptions]="{standalone: true}" autocomplete="off" />
            </div>
            <div class="col-md-5">
              <label class="form-label mt-2">Lot</label>
              <select class="form-select" name="location" [(ngModel)]="selectedTrailerLocation">
                <option [ngValue]="null">-Choose Lot-</option>
                <option *ngFor="let location of yardLocations" [ngValue]="location['locationId']">
                  {{location['locationTypeRowName']}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger me-md-2" (click)="closeModal()">Cancel</button>
          <button type="submit" class="btn btn-primary" [disabled]="invalidAssignTrailer()">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- End Assign Trailer Modal -->
<!-- Begin Yard List -->
<div class="row mt-2">
  <div class="col-lg-8 position-relative mb-3">
    <h3>SHIPPING YARD</h3>
    <div class="mb-3 row">


        <label for="craneCrews" class="col-sm-1 col-form-label">Crane Crew</label>
        <div class="col-sm-3">
          <select [(ngModel)]="selectedCraneCrew" class="form-select" id="craneCrews" (change)="searchYards()">
            <option [ngValue]="null">All Crews</option>
            <option *ngFor="let crane of craneCrews" [ngValue]="crane.craneCrewID">
              {{ crane.description }}
            </option>
          </select>
        </div>
      <div class="col-sm-5 d-inline-flex align-items-center">
        <input id="showFilter" type="checkbox" [(ngModel)]="this.showCompletelyLoaded" (change)="searchYards()" />
        <label class="ms-3" for="showFilter" value="Show Completely Loaded">
          Show Completely Loaded
        </label>
      </div>
      <div *ngIf="yards.length==0 && this.hasPerformedSearch" class="col-sm-3">No data found</div>
    </div>
</div>

<div class="col-lg-12 position-relative stickyContainer">
  <table class="table table-bordered table-hover table-sm stickyBorderBottom" style="top: -1px;">
    <thead class="table-light sticky-top top-0">
      <tr>
        <th class="text-nowrap stickyBorder"></th>
        <th class="text-nowrap stickyBorder" (click)="setOrder('loadDescriptor')">
          Ld
          <span *ngIf="order === 'loadDescriptor'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
          <span *ngIf="order === 'loadDescriptor'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
          <span *ngIf="order !== 'loadDescriptor'"><i class="fa-solid fa-sort"></i></span>
        </th>
        <th class="text-nowrap stickyBorder" (click)="setOrder('job')">
          Job
          <span *ngIf="order === 'job'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
          <span *ngIf="order === 'job'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
          <span *ngIf="order !== 'job'"><i class="fa-solid fa-sort"></i></span>
        </th>
        <th class="text-nowrap stickyBorder" (click)="setOrder('loadDescriptor')">
          Mk
          <span *ngIf="order === 'loadDescriptor'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
          <span *ngIf="order === 'loadDescriptor'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
          <span *ngIf="order !== 'loadDescriptor'"><i class="fa-solid fa-sort"></i></span>
        </th>
        <th class="text-nowrap stickyBorder" (click)="setOrder('trailerLocationRow')">
          Loc
          <span *ngIf="order === 'trailerLocationRow'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
          <span *ngIf="order === 'trailerLocationRow'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
          <span *ngIf="order !== 'trailerLocationRow'"><i class="fa-solid fa-sort"></i></span>
        </th>
        <th class="text-nowrap stickyBorder" (click)="setOrder('trailerDescription')">
          Truck Descr
          <span *ngIf="order === 'trailerDescription'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
          <span *ngIf="order === 'trailerDescription'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
          <span *ngIf="order !== 'trailerDescription'"><i class="fa-solid fa-sort"></i></span>
        </th>
        <th class="text-nowrap stickyBorder" (click)="setOrder('carrierName')">
          Carrier
          <span *ngIf="order === 'carrierName'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
          <span *ngIf="order === 'carrierName'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
          <span *ngIf="order !== 'carrierName'"><i class="fa-solid fa-sort"></i></span>
        </th>
        <th class="text-nowrap stickyBorder" (click)="setOrder('maxYardWidth')">
          Wd
          <span *ngIf="order === 'maxYardWidth'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
          <span *ngIf="order === 'maxYardWidth'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
          <span *ngIf="order !== 'maxYardWidth'"><i class="fa-solid fa-sort"></i></span>
        </th>
        <th class="text-nowrap stickyBorder" (click)="setOrder('maxYardLength')">
          Ln
          <span *ngIf="order === 'maxYardLength'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
          <span *ngIf="order === 'maxYardLength'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
          <span *ngIf="order !== 'maxYardLength'"><i class="fa-solid fa-sort"></i></span>
        </th>
        <th class="text-nowrap stickyBorder" (click)="setOrder('totalWeight')">
          Wt
          <span *ngIf="order === 'totalWeight'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
          <span *ngIf="order === 'totalWeight'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
          <span *ngIf="order !== 'totalWeight'"><i class="fa-solid fa-sort"></i></span>
        </th>
        <th class="text-nowrap stickyBorder" (click)="setOrder('deliveryDateTime')">
          Delivery
          <span *ngIf="order === 'deliveryDateTime'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
          <span *ngIf="order === 'deliveryDateTime'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
          <span *ngIf="order !== 'deliveryDateTime'"><i class="fa-solid fa-sort"></i></span>
        </th>
        <th class="text-nowrap stickyBorder" (click)="setOrder('checkInTimestamp')">
          Check In
          <span *ngIf="order === 'checkInTimestamp'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
          <span *ngIf="order === 'checkInTimestamp'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
          <span *ngIf="order !== 'checkInTimestamp'"><i class="fa-solid fa-sort"></i></span>
        </th>
        <th class="text-nowrap stickyBorder" (click)="setOrder('isPreLoad')">
          P-LD
          <span *ngIf="order === 'isPreLoad'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
          <span *ngIf="order === 'isPreLoad'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
          <span *ngIf="order !== 'isPreLoad'"><i class="fa-solid fa-sort"></i></span>
        </th>
        <th class="text-nowrap stickyBorder" (click)="setOrder('trailerDescription')">
          Loaded
          <span *ngIf="order === 'trailerDescription'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
          <span *ngIf="order === 'trailerDescription'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
          <span *ngIf="order !== 'trailerDescription'"><i class="fa-solid fa-sort"></i></span>
        </th>
      </tr>
    </thead>
    <tbody *ngFor="let yard of yards | orderBy:propertyName:reverse">
      <!-- | orderBy: order:reverse:true track yard; -->
      <!-- <tr>{{propertyName}}</tr> -->
      <tr style="border: 1px solid lightgray;">
        <td>
          <button type="button" (click)="toggleExpanded(yard)"
                  style=" outline: none; border: none; background: transparent; cursor: pointer;">
            <div *ngIf="!yard.isExpanded">
              <i class="fa-solid fa-caret-right"></i>
            </div>
            <div *ngIf="yard.isExpanded">
              <i class="fa-solid fa-caret-down"></i>
            </div>
          </button>
        </td>
        <td [ngStyle]="{ backgroundColor: yard.isQAIssue ? 'yellow' : '' }">
          <div *ngIf="yard.isQAIssue">
            <i class="fa fa-exclamation" title="Unresolved QA Issue" style="color: red;"></i>
            {{yard?.loadDescriptor}}
          </div>
          <div *ngIf="!yard.isQAIssue">
            {{yard?.loadDescriptor}}
          </div>
        </td>
        <td [ngStyle]="{ backgroundColor: yard.isQAIssue ? 'yellow' : '' }">
          {{yard?.job}}
        </td>
        <td [ngStyle]="{ backgroundColor: yard.isQAIssue ? 'yellow' : '' }">
          <span></span>
        </td>
        <td [ngStyle]="{ backgroundColor: yard.isQAIssue ? 'yellow' : '' }">
          {{yard?.trailerLocationRow}}
        </td>
        <td style="width:50px" [ngStyle]="{ backgroundColor: yard.isQAIssue ? 'yellow' : '' }">
          {{yard?.tractorDescription}}
        </td>
        <td style="width:50px" [ngStyle]="{ backgroundColor: yard.isQAIssue ? 'yellow' : '' }">
          {{yard?.carrierName}}
        </td>
        <td [ngStyle]="{ backgroundColor: yard.isQAIssue ? 'yellow' : '' }">
          <span *ngIf="yard?.maxYardWidth !== -1">
            {{yard?.maxYardWidth | number: '1.3-3'}}
          </span>
        </td>
        <td [ngStyle]="{ backgroundColor: yard.isQAIssue ? 'yellow' : '' }">
          <span *ngIf="yard?.maxYardLength !== -1">
            {{yard?.maxYardLength | number: '1.3-3'}}
          </span>
        </td>
        <td [ngStyle]="{ backgroundColor: yard.isQAIssue ? 'yellow' : '' }">
          {{yard?.totalWeight | number: '0.0-0'}}
        </td>
        <td [ngStyle]="{ backgroundColor: yard.isQAIssue ? 'yellow' : '' }">
          {{yard?.deliveryDateTime ?? yard.expShipDate| date: 'M/d/y HH:mm'}}
        </td>
        <td [ngStyle]="{ backgroundColor: yard.isQAIssue ? 'yellow' : '' }">
          {{yard?.checkInTimestamp | date: 'HH:mm'}}
        </td>
        <td [ngStyle]="{ backgroundColor: yard.isQAIssue ? 'yellow' : '' }">
          <i *ngIf="yard.isPreLoad" class="fa-solid fa-check  me-3" title="Load is a Pre-Load" style="color: green;"></i>
          <span *ngIf="allowLoadUnloadAll();">
            <button type="button" (click)="loadTrailerPanelModal(yard, yard.panels[0], false, true)"
                    [ngClass]="yard.isTrailerLoaded ? 'btn btn-danger btn-sm col-md-6' : 'btn btn-success btn-sm col-md-6' ">
              {{ yard.isTrailerLoaded ? 'Unload All' : 'Load All' }}
            </button>
          </span>
        </td>
        <td style="width:100px" [ngStyle]="{ backgroundColor: yard.isQAIssue ? 'yellow' : '' }">
          <i *ngIf="yard.isTrailerLoaded" class="fa-solid fa-check" title="Load completed" style="color: green;"></i>
          {{ yard.trailerDescription }}
        </td>
      </tr>
      <tr [collapse]="!yard.isExpanded" *ngFor="let panel of yard.panels; even as isEven; " class="border-0">
        <!-- track panel; -->
        <td colspan="3" class="border-0 emptyColumnColor" style="border-color: lightgrey; border-top: hidden;"></td>
        <td class="border-1 hiddenBorder" style="white-space: nowrap;"
            [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
          <div *ngIf="panel.isQAIssue">
            <i class="fa fa-exclamation" title="Unresolved QA Issue" style="color: red;"></i>
            {{panel.mark}}
          </div>
          <div *ngIf="!panel.isQAIssue">
            {{panel.mark}}
          </div>
        </td>
        <td colspan="3" class="border-1 hiddenBorder" style="text-align: right;"
            [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
          {{panel.locationName}}
        </td>
        <td class="border-1 hiddenBorder" [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
          {{panel.width | number: '1.3-3'}}
        </td>
        <td class="border-1 hiddenBorder" [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
          {{panel.length | number: '1.3-3'}}
        </td>
        <td class="border-1 hiddenBorder" [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
          {{panel.weight | number: '1.0'}}
        </td>
        <td class="border-1 hiddenBorder" [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
        </td>
        <td class="border-1 hiddenBorder" [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
        </td>
        <td colspan="2" class="border-1 hiddenBorder" [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
          <i *ngIf="panel.pieceId == 0" class="fa fa-exclamation" title="Missing QA Info..." style="color: red;"></i>
          <span *ngIf="panel.pieceId > 0">
            <button type="button" (click)="loadTrailerPanelModal(yard, panel, false)"
                    [ngClass]="panel.isTrailerLoaded ? 'btn btn-danger btn-sm col-md-4 ' : 'btn btn-success btn-sm col-md-4'  ">
              {{ panel.isTrailerLoaded ? 'Unload' : 'Load' }}
            </button>

            <span *ngIf="authService.currentUserSig()?.hasMarkNumReassignPermission && (!yard.isPreLoad && !panel.isTrailerLoaded) ">
              <button type="button" (click)="loadTrailerPanelModal(yard, panel, true)"
                      class='ml-1 btn btn-primary btn-sm col-md-4'>
                Edit
              </button>
            </span>
          </span>
        </td>
      </tr>
      <tr [collapse]="!yard.isExpanded" *ngFor="let customItem of yard.customItems; even as isEven" class="border-0">
        <td colspan="3" class="border-0 emptyColumnColor" style="border-color: lightgrey; border-top: hidden;"></td>
        <td colspan="6" class="border-1 hiddenBorder" [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
          {{ customItem.itemDescription }} - {{ customItem.quantity }}
        </td>
        <td colspan="3" class="border-1 hiddenBorder" [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
          {{customItem.weight | number: '1.0'}}
        </td>
        <td colspan="3" class="border-1 hiddenBorder" [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
          <button type="button" (click)="loadTrailerCustomModal(yard, customItem, false)"
                  [ngClass]="customItem.isTrailerLoaded ? 'btn btn-danger btn-sm col-md-4 text-center' : 'btn btn-success btn-sm col-lg-4 text-center'">
            {{yard.isPreload}}
            {{ customItem.isTrailerLoaded ? 'Unload' : 'Load' }}
          </button>
          <span *ngIf="authService.currentUserSig()?.hasMarkNumReassignPermission && (!yard.isPreLoad) ">
            <button type="button" (click)="loadTrailerCustomModal(yard, customItem, true)"
                    class='btn btn-primary btn-sm col-md-4 '>
              Edit
            </button>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</div>
<!-- End Yard List -->
