<!-- Begin Add Shipping Setup Modal -->
<div class="modal fade modal-lg" id="editShippingSetupModal" tabindex="-1" aria-labelledby="addHolidayModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="editShippingSetupLabel">Load {{selectedShippingSetup.loadNumber}} Details</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form #form="ngForm" class="modal-body needs-validation" (ngSubmit)="saveCustomItems()" novalidate>
        <div class="row">
          <div class="mb-3 position-relative col-lg-6">
            <label class="form-label" for="carrierName">Carrier Name</label>
            <input type="text" class="form-control" id="carrierName" name="carrierName"
              aria-describedby="carrierName carrierNameFeedback" [(ngModel)]="selectedShippingSetup.carrierName"
              placeholder="" autocomplete="off">
          </div>
          <div class="mb-3 position-relative col-lg-3">
            <label class="form-label">Crane Crew</label>
            <select #checkinCraneCrews class="form-select" name="craneCrewID"
              [(ngModel)]="selectedShippingSetup.craneCrewId">
              <option [ngValue]=null>-Unassigned-</option>
              <option *ngFor="let crane of craneCrews" [ngValue]="crane.craneCrewID">
                {{crane['description']}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label class="form-label" for="trailerNumber">Trailer Number</label>
            <select class="form-select" name="trailerNumber" [(ngModel)]="selectedShippingSetup.trailerId">
                <option [ngValue]="null">-Choose Trailer-</option>
                <option *ngFor="let trailer of yardTrailers" [ngValue]="trailer.trailerId">
                  {{trailer.trailerName}}
                </option>
              </select>
          </div>
          <div class="position-relative col-lg-3">
            <label class="form-label" for="trailerNumberText">&nbsp; </label>
            <input type="text" class="form-control" id="trailerNumberText" name="trailerNumberText"
              aria-describedby="trailerNumberText trailerNumberTextFeedback" [(ngModel)]="selectedShippingSetup.trailer"
              placeholder="Trailer Number" autocomplete="off">
          </div>
        </div>
        <div class="row mt-2">
          <div class="mb-3 position-relative col-lg-3">
            <label class="form-label" for="trailerSize">Trailer Size</label>
            <input type="number" class="form-control" id="trailerSize" name="trailerSize"
              aria-describedby="trailerSize trailerSizeFeedback" [(ngModel)]="selectedShippingSetup.trailerSize"
              placeholder="" autocomplete="off">
          </div>
          <div class="mb-3 position-relative col-lg-3">
            <label class="form-label" for="loadingType">Loading Type</label>
            <input type="text" class="form-control" id="loadingType" name="loadingType"
              aria-describedby="loadingType loadingTypeFeedback" [(ngModel)]="selectedShippingSetup.loadingType"
              placeholder="" autocomplete="off">
          </div>
        </div>

        <!-- <div class="mb-3 position-relative col-lg-6">

        </div> -->
        <div class="mb-3 position-relative">
          <div>
            <label class="form-label" for="carrierName">Additional Line Items</label>
          </div>
          <div>
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col">Item</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Description</th>
                  <th scope="col">Weight (EA)</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of selectedShippingSetup.customItems; let i = index; track item">
                  <td>
                    <select class="form-select" name="selectCustomItem{{i}}" [(ngModel)]="item.customItemId">
                      <!-- [(ngModel)]="schedule.companyId" -->
                      <option *ngFor="let custom of customItems" [ngValue]="custom.customItemId">
                        {{ custom.customItemDesc }}
                      </option>
                    </select>
                  </td>
                  <td class="col-md-2">
                    <input type="number" min="1" class="form-control" name="itemQuantity{{i}}"
                           aria-describedby="itemQuantity itemQuantityFeedback" [(ngModel)]="item.quantity">
                  </td>
                  <td>
                    <input type="text" class="form-control" name="itemDescription{{i}}"
                           aria-describedby="itemDescription itemDescriptionFeedback" [(ngModel)]="item.description">
                  </td>
                  <td>
                    <input type="number" min="0" class="form-control" name="itemWeight{{i}}"
                           aria-describedby="itemWeight itemWeightFeedback" [(ngModel)]="item.weight">
                  </td>
                  <td style="text-align: center;">
                    <div (click)="deleteCustomItem(item.customItemId)">
                      <i class="fa fa-trash-can"
                         style="color: #dc3545; cursor: hand; text-align: center;" title="Delete"></i>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="selectedShippingSetup.customItems.length==0">
                  <td colspan="4"><label>No custom items in this load</label></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="mb-2">
          <button type="button" class="btn btn-success btn-sm" (click)="addCustomItem();">
            <i class="fa-solid fa-square-plus"></i>
            ADD LINE ITEM
          </button>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-danger me-md-2" data-bs-dismiss="modal"
            (click)="cancel()">Cancel</button>
          <button type="submit" class="btn btn-primary" type="submit" data-bs-dismiss="modal">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- End Add Holiday Modal -->
<!-- Begin Setup List -->
<div class="row mt-2">
  <div class="col-lg-8 position-relative mb-3">
    <h3>SHIPPING SETUP</h3>
    <div class="row">
      <div class="col-md-3 form-group mb-3">
        <input type="text" placeholder="Enter Job Number" class="form-control" [(ngModel)]="searchJobNumber" (keydown.enter)="getSearchLoads()">
      </div>
      <div class="col-md-2 form-group mb-3">
        <button type="button" class="btn btn-primary btn-sm me-md-2" (click)="getSearchLoads()"
          [disabled]="invalidSearch()">
          <i class="fa-solid fa-magnifying-glass"></i>
          SEARCH
        </button>
      </div>
    </div>
  </div>
  <div class="col-lg-10 position-relative stickyContainer">
    <table class="table table-bordered table-hover table-sm stickyBorderBottom">
      <thead class="table-light sticky-top top-0">
        <tr>
          <th class="stickyBorder"></th>
          <th class="stickyBorder" (click)="setOrder('loadDescriptor')">
            Ld <span *ngIf="order === 'loadDescriptor'"
                     [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'loadDescriptor'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'loadDescriptor'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" (click)="setOrder('')">
            Mk <span *ngIf="order === ''" [hidden]="reverse">
              <i class="fa-solid fa-sort-down"></i>
            </span>
            <span *ngIf="order === ''" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== ''"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" (click)="setOrder('castLocation')">
            Loc <span *ngIf="order === 'castLocation'"
                      [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'castLocation'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'castLocation'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" (click)="setOrder('maxWidth')">
            Wd <span *ngIf="order === 'maxWidth'"
                     [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'maxWidth'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'maxWidth'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" (click)="setOrder('maxLength')">
            Ln <span *ngIf="order === 'maxLength'"
                     [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'maxLength'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'maxLength'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" (click)="setOrder('totalWeight')">
            Wt <span *ngIf="order === 'totalWeight'"
                     [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'totalWeight'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'totalWeight'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" (click)="setOrder('maxSquareFeet')">
            SqF <span *ngIf="order === 'maxSquareFeet'"
                      [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'maxSquareFeet'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'maxSquareFeet'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" (click)="setOrder('deliveryDateTime')">
            Del Date <span *ngIf="order === 'deliveryDateTime'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'deliveryDateTime'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'deliveryDateTime'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" (click)="setOrder('deliveryDateTime')">
            Del Time <span *ngIf="order === 'deliveryDateTime'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'deliveryDateTime'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'deliveryDateTime'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" (click)="setOrder('carrierName')">
            Carrier <span *ngIf="order === 'carrierName'"
                          [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'carrierName'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'carrierName'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" (click)="setOrder('trailerSize')">
            Trailer Size <span *ngIf="order === 'trailerSize'"
                               [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'trailerSize'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'trailerSize'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" (click)="setOrder('trailerDescription')">
            Trailer<span *ngIf="order === 'trailerDescription'"
                         [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'trailerDescription'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'trailerDescription'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" (click)="setOrder('loadingType')">
            Loading Type<span *ngIf="order === 'loadingType'"
                              [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'loadingType'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'loadingType'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" style="width: 170px;">
            Select All <input type="checkbox" class="form-check-input"
                              [value]="selectAllSetups" [checked]="selectAllSetups" [(ngModel)]="selectAllSetups"
                              (click)="toggleCheckAll($event)">
          </th>
          <th class="stickyBorder"></th>
        </tr>
      </thead>
      <tbody *ngFor="let setup of shippingSetups | orderBy:propertyName:reverse" > <!-- | orderBy: order:reverse:false --> <!-- *ngFor="let setup of shippingSetups; trackBy: trackByFn"-->
        <tr>
          <td [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''">
            <button type="button" (click)="toggleExpanded(setup.panels); toggleCustomItemExpanded(setup.customItems);"
                    style=" outline: none; border: none; background: transparent; cursor: pointer;" [disabled]="isLoading">
              <i class="fa-solid fa-caret-right"></i>
            </button>
          </td>
          <td [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''">
            <div *ngIf="setup.isQAIssue">
              <i class="fa fa-exclamation" title="Unresolved QA Issue" style="color: red;"></i>
              {{setup.loadDescriptor}}
            </div>
            <div *ngIf="!setup.isQAIssue">
              {{setup.loadDescriptor}}
            </div>
          </td>
          <td [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''"></td>
          <td [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''">{{ setup.castLocation }}</td>
          <td [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''">{{ setup.maxWidth | number:'1.3-3' }}</td>
          <td [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''">{{ setup.maxLength | number: '1.3-3' }}</td>
          <td [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''">{{ setup.totalWeight | number:'1.0' }}</td>
          <td [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''">{{ setup.maxSquareFeet | number:'1.0-0' }}</td>
          <td [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''">{{ setup.deliveryDateTime | date: 'M/d/y'}}</td>
          <td [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''">{{ setup.deliveryDateTime | date: 'HH:mm'}}</td>
          <td [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''">{{ setup.carrierName }}</td>
          <td [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''">{{ setup.trailerSize }}</td>
          <td [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''">{{ setup.trailerDescription }}</td>
          <td [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''">{{ setup.loadingType }}</td>
          <td [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''">
            <button class="btn btn-sm me-2 btn-warning" style="border: solid 1px;" [disabled]="setup.isCheckedOut"
                    data-bs-toggle="modal" data-bs-target="#editShippingSetupModal" (click)="editSetup(setup)"
                    [disabled]="setup.isCheckedOut">
              <i class="fa fa-pencil"></i> Edit
            </button>
            <input class="form-check-input" type="checkbox" [(ngModel)]="setup.isPreLoad" [disabled]="setup.isLoaded"
                   (click)="togglePreLoad(setup);">
            <label class="form-check-label" for="checkPreload" style="font-weight: bold;">
              Preload
            </label>
          </td>
          <td style="width: 60px;" [ngClass]="setup.isCheckedOut ? 'checkedOut' : ''">
            <span style="color: green;" *ngIf="setup.hasItems">
              <i class="fa fa-plus green me-1" title="Has additional items in load"></i>
            </span>
            <a (click)="printLoadList(setup.loadDescriptor)">
              <i class="fa fa-print float-end" title="Print Load List" style="height: 20px; cursor: pointer;"></i>
            </a>
          </td>
        </tr>
        <tr [collapse]="!panel.isExpanded" *ngFor="let panel of setup.panels; let i = index; even as isEven"
          title="{{setup.isCheckedOut ? 'Cannot modify once load has checked out' : ''}}" class="border-0">
          <td colspan="2" class="border-0" style="border-color: lightgrey; border-top: hidden;"></td>
          <td class="border-1" style="border-color: lightgrey; border-top: hidden;"
            [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
            <div *ngIf="panel.isQAIssue">
              <i class="fa fa-exclamation" title="Unresolved QA Issue" style="color: red;"></i>
              {{panel.mark}}
            </div>
            <div *ngIf="!panel.isQAIssue">
              {{panel.mark}}
            </div>
          </td>
          <td class="border-1" style="border-color: lightgrey; border-top: hidden;"
            [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">{{ panel.locationName }}</td>
          <td class="border-1" style="border-color: lightgrey; border-top: hidden;"
            [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">{{ panel.width | number: '1.3-3' }}
          </td>
          <td class="border-1" style="border-color: lightgrey; border-top: hidden;"
            [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">{{ panel.length | number: '1.3-3' }}
          </td>
          <td class="border-1" style="border-color: lightgrey; border-top: hidden;"
            [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">{{ panel.weight | number:'1.0' }}
          </td>
          <td class="border-1" style="border-color: lightgrey; border-top: hidden;"
            [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">{{ panel.squareFeet | number:
            '1.0-0' }}</td>
          <td class="border-1" style="border-color: lightgrey; border-top: hidden;"
            [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }"></td>
          <td class="border-1" style="border-color: lightgrey; border-top: hidden;"
            [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }"></td>
          <td class="border-1" style="border-color: lightgrey; border-top: hidden;"
            [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }"></td>
          <td class="border-1" style="border-color: lightgrey; border-top: hidden;"
            [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
            <button type="button" class="btn btn-success btn-sm me-md-2" *ngIf="panel.allowUp"
              [disabled]="setup.isCheckedOut" (click)="movePanelUp(setup, panel)">
              <i class="fa-solid fa-arrow-up"></i>
            </button>
            <button type="button" class="btn btn-success btn-sm me-md-2" *ngIf="panel.allowDown"
              [disabled]="setup.isCheckedOut" (click)="movePanelDown(setup, panel)">
              <i class="fa-solid fa-arrow-down"></i>
            </button>
          </td>
        </tr>
        <ng-container *ngFor="let panelDesc of setup.panels; let i = index; even as isEven">
          <tr *ngIf="panelDesc.description != null" [collapse]="!panelDesc.isExpanded" class="border-0">
            <td colspan="2" class="border-0"></td>
            <td colspan="10" class="border-1" style="border-color: lightgrey; border-top: hidden;"
              [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
              <label>{{ panelDesc.description + ' - Description'}}</label>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngFor="let customItem of setup.customItems; let i = index; even as isEven">
          <tr [collapse]="!customItem.isExpanded" class="border-0">
            <td colspan="2" class="border-0"></td>
            <td colspan="4" class="border-1" style="border-color: lightgrey; border-top: hidden;"
              [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
              <label>{{customItem.itemDescription}} - {{customItem.quantity}} {{customItem?.description ? " - " +
                customItem?.description : ""}} </label>
            </td>
            <td colspan="6" class="border-1" style="border-color: lightgrey; border-top: hidden;"
                [ngStyle]="{ backgroundColor: isEven ? 'lightgray' : 'white' }">
              {{customItem.weight * customItem.quantity | number: '1.0'}}
            </td>
          </tr>
        </ng-container>

      </tbody>
    </table>
  </div>
</div>

<!-- End Setup List -->
