import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ShippedLoadPanel } from './shipping-unship-load.model';

const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable({
  providedIn: 'root'
})
export class ShippingUnshipLoadService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }

  getShippedLoad(job: string, loadNum: string, companyId: number):Observable<ShippedLoadPanel[]> {    
    try {
      return this.http.get<ShippedLoadPanel[]>(`${this.baseUrl}/unship/search?job=${job}&&load=${loadNum}&&companyId=${companyId}`).pipe(map((searchResults) => { return searchResults; }))
    } catch (error) {
      console.log(error);
      return new Observable<ShippedLoadPanel[]>();
    }
  }

  unshipLoad(job: string, loadNum: string, companyId: number, originalCheckOutDate: string, sendEmail: boolean, comments: string): Observable<ShippedLoadPanel[]> {
    try {
      return this.http.post<ShippedLoadPanel[]>(`${this.baseUrl}/unship/load?job=${job}&&load=${loadNum}&&companyId=${companyId}&&originalCheckOutDate=${originalCheckOutDate}&&sendEmail=${sendEmail}&&comments=${comments}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<ShippedLoadPanel[]>();
    }
  }

  unshipPanels(job: string, loadNum: string, jobMarks: string, originalCheckOutDate: string, sendEmail: boolean, comments: string): Observable<ShippedLoadPanel[]> {
    try {
      return this.http.post<ShippedLoadPanel[]>(`${this.baseUrl}/unship/panels?job=${job}&&load=${loadNum}&&panels=${jobMarks}&&originalCheckOutDate=${originalCheckOutDate}&&sendEmail=${sendEmail}&&comments=${comments}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<ShippedLoadPanel[]>();
    }
  }
}
