<div class="col-lg-12 position-relative">
  <h3>UNSHIP LOAD</h3>
  <div class="row mb-2 align-items-center">
    <div class="col-lg-2 col-sm-4 d-inline-flex ">
      <input id="job" [(ngModel)]='this.searchedJob' type="text" placeholder="Job Number" class="form-control me-2"  (keydown.enter)="searchLoad()">
      <input id="loadNum" [(ngModel)]='this.searchedLoad' type="text" placeholder="Load Number" class="form-control"  (keydown.enter)="searchLoad()">
    </div>
    <div class="col-lg-3 col-sm-4 d-inline-flex">
      <button type="button" class="btn btn-primary btn-sm me-md-2" [disabled]="invalidSearch()" (click)='searchLoad()'>
        <i class="fa-solid fa-magnifying-glass"></i>
        SEARCH
      </button>
      <button type="button" class="btn btn-danger btn-sm" [disabled]="invalidSearch()" (click)='clearSearch()'>
        <i class="fa-regular fa-circle-xmark"></i>
        CLEAR
      </button>
    </div>
    <div *ngIf="allowSave()" class="col-lg-3 col-sm-4 d-inline-flex">
      <button type="button" class="btn btn-primary btn-sm me-md-2" (click)='openModal(sendNotification)'>
        <i class="fa-solid fa-magnifying-glass"></i>
        Save
      </button>
      <button type="button" class="btn btn-danger btn-sm" (click)='cancel()'>
        <i class="fa-regular fa-circle-xmark"></i>
        Cancel
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4">
      <div *ngIf="this.shippedLoad.length==0 && this.hasPerformedSearch" role="alert">No data found based on search criteria</div>
      <div *ngIf="this.shippedLoad.length > 0 && this.hasPerformedSearch && this.unshippedLoadSearched" role="alert">Load  has not been shipped</div>
    </div>
  </div>
  <div *ngIf="this.hasPerformedSearch && !this.unshippedLoadSearched && this.shippedLoad.length > 0" class="col-lg-11 position-relative stickyContainer">
    <table class="table table-bordered table-striped table-hover table-sm stickyBorderBottom">
      <thead class="table-light sticky-top top-0">
        <tr>
          <th class="text-nowrap stickyBorder" (click)="setOrder('job')">
            Job <span *ngIf="order === 'job'" [hidden]="reverse">
              <i class="fa-solid fa-sort-down"></i>
            </span>
            <span *ngIf="order === 'job'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'job'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('loadNum')">
            Load <span *ngIf="order === 'loadNum'"
                       [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'loadNum'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'loadNum'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('mark')">
            Mark <span *ngIf="order === 'mark'"
                       [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'mark'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'mark'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('deliveryDateTime')">
            Delivery Date <span *ngIf="order === 'deliveryDateTime'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'deliveryDateTime'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'deliveryDateTime'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('checkInTimestamp')">
            Check-In Date/Time <span *ngIf="order === 'checkInTimestamp'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'checkInTimestamp'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'checkInTimestamp'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('loadedPanelDateTime')">
            Loaded Date/Time <span *ngIf="order === 'loadedPanelDateTime'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'loadedPanelDateTime'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'loadedPanelDateTime'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('checkOutTimestamp')">
            Check-Out Date/Time <span *ngIf="order === 'checkOutTimestamp'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'checkOutTimestamp'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'checkOutTimestamp'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('panelStatusText')">
            Status <span *ngIf="order === 'panelStatusText'"
                         [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'panelStatusText'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'panelStatusText'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder"><input class="mr-1" id="cb_selectAll" [(ngModel)]='this.unshipEntireLoad' type="checkbox" (change)="onCheckboxChange()"> Unship Entire Load</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let panel of this.shippedLoad | orderBy:propertyName:reverse ">
          <!-- | orderBy: order:reverse:true -->

          <td>
            <span>{{panel?.job}}</span>
          </td>
          <td>
            <span>{{panel?.loadNum}}</span>
          </td>
          <td>
            <span>{{panel?.mark}}</span>
          </td>
          <td>
            <span>{{panel?.deliveryDateTime | date:'MM/dd/y'}}</span>
          </td>
          <td>
            <span>{{panel?.checkInTimestamp | date:'MM/dd/y hh:mm a'}}</span>
          </td>
          <td>
            <span>{{panel?.loadedPanelDateTime | date:'MM/dd/y hh:mm a'}}</span>
          </td>
          <td>
            <span>{{panel?.checkOutTimestamp | date:'MM/dd/y hh:mm a'}}</span>
          </td>
          <td [ngStyle]="{'background-color': panel.color, 'color': panel.fontColor}">
            <span> {{panel.panelStatusText}}</span>
          </td>
          <td>
            <input class="mr-1" [(ngModel)]='panel.selected' (change)="onPanelCheckboxChange()" type="checkbox">  Unship Piece
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="this.unshippedLoad.length > 0">
    <h5>After Update</h5>
    <div class="col-lg-11 position-relative stickyContainer">
      <table class="table table-bordered table-striped table-hover table-sm stickyBorderBottom">
        <thead class="table-light sticky-top top-0">
          <tr>
            <th class="text-nowrap stickyBorder" (click)="setOrder('job')">
              Job <span *ngIf="order === 'job'" [hidden]="reverse">
                <i class="fa-solid fa-sort-down"></i>
              </span>
              <span *ngIf="order === 'job'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
              <span *ngIf="order !== 'job'"><i class="fa-solid fa-sort"></i></span>
            </th>
            <th class="text-nowrap stickyBorder" (click)="setOrder('loadNum')">
              Load <span *ngIf="order === 'loadNum'"
                         [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
              <span *ngIf="order === 'loadNum'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
              <span *ngIf="order !== 'loadNum'"><i class="fa-solid fa-sort"></i></span>
            </th>
            <th class="text-nowrap stickyBorder" (click)="setOrder('mark')">
              Mark <span *ngIf="order === 'mark'"
                         [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
              <span *ngIf="order === 'mark'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
              <span *ngIf="order !== 'mark'"><i class="fa-solid fa-sort"></i></span>
            </th>
            <th class="text-nowrap stickyBorder" (click)="setOrder('deliveryDateTime')">
              Delivery Date <span *ngIf="order === 'deliveryDateTime'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
              <span *ngIf="order === 'deliveryDateTime'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
              <span *ngIf="order !== 'deliveryDateTime'"><i class="fa-solid fa-sort"></i></span>
            </th>
            <th class="text-nowrap stickyBorder" (click)="setOrder('checkInTimestamp')">
              Check-In Date/Time <span *ngIf="order === 'checkInTimestamp'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
              <span *ngIf="order === 'checkInTimestamp'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
              <span *ngIf="order !== 'checkInTimestamp'"><i class="fa-solid fa-sort"></i></span>
            </th>
            <th class="text-nowrap stickyBorder" (click)="setOrder('loadedPanelDateTime')">
              Loaded Date/Time <span *ngIf="order === 'loadedPanelDateTime'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
              <span *ngIf="order === 'loadedPanelDateTime'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
              <span *ngIf="order !== 'loadedPanelDateTime'"><i class="fa-solid fa-sort"></i></span>
            </th>
            <th class="text-nowrap stickyBorder" (click)="setOrder('checkOutTimestamp')">
              Check-Out Date/Time <span *ngIf="order === 'checkOutTimestamp'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
              <span *ngIf="order === 'checkOutTimestamp'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
              <span *ngIf="order !== 'checkOutTimestamp'"><i class="fa-solid fa-sort"></i></span>
            </th>
            <th class="text-nowrap stickyBorder" (click)="setOrder('panelStatusText')">
              Status <span *ngIf="order === 'panelStatusText'"
                           [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
              <span *ngIf="order === 'panelStatusText'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
              <span *ngIf="order !== 'panelStatusText'"><i class="fa-solid fa-sort"></i></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let panel of this.unshippedLoad | orderBy:propertyName:reverse ">
            <!-- | orderBy: order:reverse:true -->

            <td>
              <span>{{panel?.job}}</span>
            </td>
            <td>
              <span>{{panel?.loadNum}}</span>
            </td>
            <td>
              <span>{{panel?.mark}}</span>
            </td>
            <td>
              <span>{{panel?.deliveryDateTime | date:'MM/dd/y'}}</span>
            </td>
            <td>
              <span>{{panel?.checkInTimestamp | date:'MM/dd/y hh:mm a'}}</span>
            </td>
            <td>
              <span>{{panel?.loadedPanelDateTime | date:'MM/dd/y hh:mm a'}}</span>
            </td>
            <td>
              <span>{{panel?.checkOutTimestamp | date:'MM/dd/y hh:mm a'}}</span>
            </td>
            <td [ngStyle]="{'background-color': panel.color, 'color': panel.fontColor}">
              <span> {{panel.panelStatusText}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #sendNotification class="modal-dialog-centered">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Send Notification</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Job {{this.searchedJob}} - Load {{this.searchedLoad}} is being unshipped? Would you like to send an email notification?</p>
     <textarea id="alertText" class="col-12" [(ngModel)]="unshipComments" rows="6"></textarea>
  </div>
  <div class="modal-footer">
    <!--<button type="button" class="btn btn-light" data-bs-dismiss="modal"
            (click)="setNotifyAction(false); bsModalRef?.hide(); unshipLoad();">
      Don't Send
    </button>-->
    <button type="button" class="btn btn-sm btn-primary" (click)="setNotifyAction(true); bsModalRef?.hide(); unshipLoad();">Send Alert</button>  
</div>
</ng-template>

