import { Component, EventEmitter, Inject, LOCALE_ID, OnInit, Output, TemplateRef, inject } from '@angular/core';
import { ShippingOfficeCheckinService } from './shipping-office-checkin.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ShippingOffice } from '../shipping-office.model';
import { ShippingOfficeCheckInCraneCrew } from './shipping-office-checkin-crane-crew.model';
import { ShippingOfficeCheckInLoad } from './shipping-office-checkin-load.model';
import { formatDate } from '@angular/common';
import { ErrorResponse } from 'src/app/error-handling/error-response.model';
import { ToastService, toastTypes } from 'src/app/toast/toast.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ShippingOfficeService } from '../shipping-office.service';

@Component({
  selector: 'app-shipping-office-checkin',
  templateUrl: './shipping-office-checkin.component.html',
  styleUrl: './shipping-office-checkin.component.css'
})
export class ShippingOfficeCheckinComponent implements OnInit {
  bsModalRef?: BsModalRef;
  title?: string;
  closeBtnName?: string;
  list: string[] = [];
  selectedOffice!: ShippingOffice; // | undefined;
  shippingOfficeCheckinService: ShippingOfficeCheckinService = inject(ShippingOfficeCheckinService);
  craneCrews: ShippingOfficeCheckInCraneCrew[] = [];
  jobLoads: ShippingOfficeCheckInLoad[] = [];
  shippingOfficeService: ShippingOfficeService = inject(ShippingOfficeService);
  @Output() confirmAction = new EventEmitter<any>();
  okAction!: string;

  constructor(@Inject(LOCALE_ID) private locale: string, private toast: ToastService, private modalService: BsModalService) { }

  ngOnInit(): void {

    if (this.selectedOffice !== undefined) {
      try {
        this.shippingOfficeCheckinService.getCraneCrews(this.selectedOffice.companyId).subscribe(result => {

          this.craneCrews = result;

        }, error => console.error(error));


        this.shippingOfficeCheckinService.getLoads(this.selectedOffice.companyId, Number(this.selectedOffice.job)).subscribe(result => {

          result.forEach((jobLoad: ShippingOfficeCheckInLoad) => {
            this.updateLoadSelect(jobLoad);
          });

          this.jobLoads = result;

          let isLoadNumIncluded: boolean = result.some(x => x.loadNumber == this.selectedOffice.loadNum);

          if (!isLoadNumIncluded && this.selectedOffice !== null) {
            let addJobload = new ShippingOfficeCheckInLoad(this.selectedOffice.jobLoadId, this.selectedOffice.isPreLoad, new Date(this.selectedOffice.deliveryDateTime),
              this.selectedOffice.loadNum!, this.selectedOffice.bolNum, this.selectedOffice.missingItems, this.selectedOffice.qaIssuePanels, 0,
              false, false, this.selectedOffice.loadNum!);
            this.jobLoads.splice(0, 0, addJobload);
          }

        }, error => console.error(error));

      } catch (error) {
        console.log(error)
      }
    }
  }

  private updateLoadSelect(jobLoad: ShippingOfficeCheckInLoad) {
    jobLoad.selectText = jobLoad.loadNumber;
    if (jobLoad.deliveryDateTime != null) {
      jobLoad.selectText += ' - ' + formatDate(jobLoad.deliveryDateTime, 'M/d/y HH:mm', this.locale)
    }
    if (jobLoad.bolNum != null) {
      jobLoad.selectText += ' - ' + jobLoad.bolNum + ' ';
    }
    if (jobLoad.width != null) {
      jobLoad.selectText += ' - W' + jobLoad.width + ' ';
    }
    if (jobLoad.isPreLoad) {
      jobLoad.selectText += ' - PreLoad';
    }
    if (jobLoad.isPreLoadComplete) {
      jobLoad.selectText += ' COMPLETE';
    }
    if (jobLoad.isQAIssue) {
      jobLoad.selectText += ' - QA Tag';
    }
  }

  saveShippingOfficeCheckIn() {

    this.selectedOffice.updateCheckInTimestamp = formatDate(new Date(), 'M/d/y HH:mm:ss', this.locale);

    this.selectedOffice.jobLoadId = this.jobLoads.find(x => x.loadNumber == this.selectedOffice.loadNum)?.jobLoadId!;

    this.shippingOfficeCheckinService.updateShippingOfficeCheckIn(this.selectedOffice).subscribe({
      next: (response: ErrorResponse) => {
        if (response.statusCode === HttpStatusCode.Ok || response.statusCode === HttpStatusCode.Created) {
          this.confirmAction.emit(this.okAction);
          this.modalService.hide();
        }
      },
      error: (error: HttpErrorResponse) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      }
    //  this.modalService.hide();

    //  this.toast.initiate({
    //    title: response.name,
    //    content: response.message,
    //    type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
    //  });

    //  this.confirmAction.emit(this.okAction);

    //}, (error: HttpErrorResponse) => {
    //  this.toast.initiate({
    //    title: error.error.name,
    //    content: error.error.message,
    //    type: toastTypes.error,
    //  });
    });
  }

  closeCheckInModal(modalId?: number) {
    this.modalService.hide(modalId);
  }

  printBOL(shippingOffice: ShippingOffice) {
    this.shippingOfficeService.getbolReport(shippingOffice.job, shippingOffice.loadNum!).subscribe(file => {

      let fileName = file.headers.get('content-disposition')?.split(';')[1].split('=')[1];
      let blob: Blob = file.body as Blob;
      let a = document.createElement('a');
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      a.click();

    }, error => console.error(error));
  }

  openModal(template: TemplateRef<void>) {
    this.bsModalRef = this.modalService.show(template);
  }

  deleteShippingOfficeCheckIn(shippingOfficeCheckIn: ShippingOffice) {
    this.shippingOfficeCheckinService.deleteShippingOfficeCheckIn(shippingOfficeCheckIn).subscribe({
      next: (response: ErrorResponse) => {
        this.closeCheckInModal();
      },
      error: (error: HttpErrorResponse) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      }
    });
  }
}
