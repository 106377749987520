import { Component, Inject, LOCALE_ID, TemplateRef, inject } from '@angular/core';
import { ToastService, toastTypes } from '../toast/toast.service';
import { ShippedLoadPanel } from './shipping-unship-load.model';
import { ShippingUnshipLoadService } from './shipping-unship-load.service';
import { AuthService } from '../authentication/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ErrorResponse } from '../error-handling/error-response.model';
import { FieldPlanEmailSentModalComponent } from '../field-plan/field-plan-detail/field-plan-email-sent-modal/field-plan-email-sent-modal.component';
import { FieldPlanDataService } from '../field-plan/field-plan.data.service';
import { FieldPlanEmailType } from '../field-plan/field-plan-detail/field-plan-email-type-enum';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-shipping-unship-load',
  templateUrl: './shipping-unship-load.component.html',
  styleUrl: './shipping-unship-load.component.css'
})
export class ShippingUnshipLoadComponent {

  authService = inject(AuthService);
  bsModalRef?: BsModalRef;
  fieldPlanDataService: FieldPlanDataService = inject(FieldPlanDataService);
  isLoading: boolean = false;
  hasPerformedSearch: boolean = false;
  propertyName: string = '';
  timeout: any;
  shippedLoad: ShippedLoadPanel[] = [];
  unshippedLoad: ShippedLoadPanel[] = [];
  unshipComments: string = '';
  sendNotification: boolean = false;

  order: string = "job";
  reverse: boolean = true;
  searchedJob: string = '';
  searchedLoad: string = '';
  unshipEntireLoad: boolean = false;
  unshippedLoadSearched: boolean = false;

  constructor(@Inject(LOCALE_ID) private locale: string, private toast: ToastService,
    private modalService: BsModalService,
    private unshipLoadService: ShippingUnshipLoadService) { }

  ngOnInit() {
  }

  searchLoad() {
    this.clearVariables()
    this.isLoading = true;
    this.unshippedLoadSearched = false;
    this.searchedLoad = this.searchedLoad.padStart(3, '0');
    this.unshipLoadService.getShippedLoad(this.searchedJob, this.searchedLoad, this.authService.currentUserSig()!.companyId).subscribe({
      next: (shippedLoadResult) => {
        shippedLoadResult?.forEach((shippedLoadPanel: ShippedLoadPanel) => {
          this.updateProperties(shippedLoadPanel);

          if (this.propertyName != '') {
            this.reverse = !this.reverse;
            this.setOrder(this.propertyName);
          }
        });

        if (shippedLoadResult != null && shippedLoadResult.length > 0 && shippedLoadResult[0].checkOutTimestamp == null) {
          this.unshippedLoadSearched = true;
        }

        this.shippedLoad = shippedLoadResult;
        this.hasPerformedSearch = true;
        this.isLoading = false;
      },
      error: (error) => {
        // Handle error cases
        this.isLoading = false;
        console.error(error);
      }
    });
  }

  clearSearch() {
    this.searchedJob = '';
    this.searchedLoad = '';
    this.shippedLoad = [];
    this.unshippedLoad = [];
    this.unshipEntireLoad = false;
    this.hasPerformedSearch = false;
  }

  clearVariables() {
    this.shippedLoad = [];
    this.unshippedLoad = [];
    this.unshipEntireLoad = false;
    this.hasPerformedSearch = false;
    this.sendNotification = false;
    this.unshipComments = '';
  }

  unshipLoad() {
    let selectedPanels = this.shippedLoad.filter(sl => sl.selected === true);
    let jobMarks: string[] = [];
    const originalCheckOutDate: string = formatDate(selectedPanels?.[0].checkOutTimestamp!, 'MM-dd-y HH:mm:ss', this.locale);
    selectedPanels.forEach(sp => {
      jobMarks.push(sp?.jobMark);
    });

    //.unship selected panels if entire load is not selected
    if (!this.unshipEntireLoad && selectedPanels.length > 0) {
      this.unshipLoadService.unshipPanels(this.searchedJob, this.searchedLoad, jobMarks.toString(), originalCheckOutDate.toString(), this.sendNotification, this.unshipComments).subscribe({
        next: (unshippedLoadResult) => {
          unshippedLoadResult.forEach((unshippedLoadPanel: ShippedLoadPanel) => {
            this.updateProperties(unshippedLoadPanel);

            if (this.propertyName != '') {
              this.reverse = !this.reverse;
              this.setOrder(this.propertyName);
            }
            this.isLoading = false;
          });
          
          this.unshippedLoad = unshippedLoadResult;
        },
        error: (error) => {
          // Handle error cases
          this.isLoading = false;
          console.error(error);
        }
      });
    }

    // unship the entire load
    if (this.unshipEntireLoad) {
      this.unshipLoadService.unshipLoad(this.searchedJob, this.searchedLoad, this.authService.currentUserSig()!.companyId, originalCheckOutDate.toString(), this.sendNotification, this.unshipComments).subscribe({
        next: (unshippedLoadResult) => {
          unshippedLoadResult.forEach((unshippedLoadPanel: ShippedLoadPanel) => {
            this.updateProperties(unshippedLoadPanel);

            if (this.propertyName != '') {
              this.reverse = !this.reverse;
              this.setOrder(this.propertyName);
            }
            this.isLoading = false;
          });

          this.unshippedLoad = unshippedLoadResult;
        },
        error: (error) => {
          // Handle error cases
          this.isLoading = false;
          console.error(error);
        }
      });
    }
  }

  cancel() {

  }

  private updateProperties(shippedLoadPanel: ShippedLoadPanel) {

    shippedLoadPanel.selected = false;
    shippedLoadPanel.panelStatusText = '';
    shippedLoadPanel.fontColor = '#000000';

    switch (shippedLoadPanel.color) {
      case '#0000FF':  //Blue
        shippedLoadPanel.panelStatusText = 'Shipped';
        shippedLoadPanel.fontColor = '#ffffff';
        break;
      case '#00FF00':  //Green
        shippedLoadPanel.panelStatusText = 'OK';
        break;
      case '#7AE8FF':  //LightBlue
        shippedLoadPanel.panelStatusText = 'Shipped - Field Tagged';
        break;
      case '#FBC235':  //Orange
        shippedLoadPanel.panelStatusText = 'Patch Tag';
        break;
      case '#FF0000': //Red
        shippedLoadPanel.panelStatusText = 'Not Cast';
        break;
      case '#58595B':   //Grey
        shippedLoadPanel.panelStatusText = 'Not Stamped'
        shippedLoadPanel.fontColor = '#ffffff';
        break;
      case '#FF02CC':  //Pink
        shippedLoadPanel.panelStatusText = 'Not Stripped';
        break;
      case '#FFFF00':  //Yellow
        shippedLoadPanel.panelStatusText = 'Awaiting Signoff';
        break;
      case '#FFFFFF':   //White
        shippedLoadPanel.panelStatusText = 'Unknown';
        break;
      default: shippedLoadPanel.panelStatusText = ' '
        break;
    }
  }

  allowSave() {
    return (this.searchedJob === this.shippedLoad?.[0]?.job || this.searchedLoad === this.shippedLoad?.[0]?.loadNum) &&
      this.shippedLoad.filter(sl => sl.selected === true).length > 0 &&
            this.unshippedLoad.length === 0;
  }

  invalidSearch(): boolean {
    if (this.searchedJob === '' || 
      this.searchedLoad === '' || 
      this.isLoading === true) {
      return true;
    }

    return false;
  }

  onCheckboxChange() {
    this.shippedLoad.forEach(sl => { sl.selected = this.unshipEntireLoad });
  }

  onPanelCheckboxChange() {
    this.unshipEntireLoad = this.shippedLoad.filter(sl => sl.selected === true).length == this.shippedLoad.length;
  }

  setOrder(value: string) {
    this.propertyName = value;

    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  openModal(template: TemplateRef<void>) {
    this.bsModalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }));  
  }

  setNotifyAction(notifyAction: boolean) {
    this.sendNotification = notifyAction;
  }
  
}

