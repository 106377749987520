import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { FieldPlanComponent } from './field-plan/field-plan.component';
import { FieldPlanDataService } from './field-plan/field-plan.data.service';
import { PanelLocationComponent } from './panel-location/panel-location.component';
import { DraggableItemService, SortableModule } from 'ngx-bootstrap/sortable';
import { Shipping3plBolComponent } from './shipping3pl-bol/shipping3pl-bol.component';
import { Shipping3plS3plComponent } from './shipping3pl-s3pl/shipping3pl-s3pl.component';
import { Shipping3plReportingComponent } from './shipping3pl-reporting/shipping3pl-reporting.component';
import { ShippingOfficeComponent } from './shipping-office/shipping-office.component';
import { ShippingSetupComponent } from './shipping-setup/shipping-setup.component';
import { ShippingYardComponent } from './shipping-yard/shipping-yard.component';
import { ShippingUnshipLoadComponent } from './shipping-unship-load/shipping-unship-load.component';

import { AdminCustomItemsComponent } from './admin-custom-items/admin-custom-items.component';
import { AdminHolidayScheduleComponent } from './admin-holiday-schedule/admin-holiday-schedule.component';
import { HomeJobTrailersComponent } from './home-job-trailers/home-job-trailers.component';
import { AdminYardCraneCrewComponent } from './admin-yard-crane-crew/admin-yard-crane-crew.component';
import { AdminTrailersComponent } from './admin-trailers/admin-trailers.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { ToastComponent } from './toast/toast.component';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CompanyDropDownComponent } from './controls/company-drop-down/company-drop-down.component';
import { ShippingOfficeCheckoutComponent } from "./shipping-office/shipping-office-checkout/shipping-office-checkout.component";
import { ShippingOfficeCheckinComponent } from './shipping-office/shipping-office-checkin/shipping-office-checkin.component';
import { ShippingDailySummaryComponent } from './shipping-office/shipping-daily-summary/shipping-daily-summary.component';
import { WriteSignaturePadComponent } from './write-signature-pad/write-signature-pad.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SpinnerLoaderComponent } from './controls/spinner-loader/spinner-loader.component';
import { OrderModule } from "./controls/order-pipe/order.module";
import { UserPreferencesComponent } from './field-plan/user-preferences-modal/user-preferences.component';
import { FieldPlanDetailComponent } from './field-plan/field-plan-detail/field-plan-detail.component';
import { LoginComponent } from './login/login.component';
import { canActivateGuard } from './guards/auth.guard';
import { customInterceptor } from './authentication/custom.interceptor';
import { CDK_DRAG_CONFIG, DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MultipleDragDropComponent } from './field-plan/field-plan-detail/multiple-drag-drop/multiple-drag-drop.component';

//This is used for dragging a panel or load.
const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    ToastComponent,
    CompanyDropDownComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    FieldPlanComponent,
    FieldPlanDetailComponent,
    PanelLocationComponent,
    AdminHolidayScheduleComponent,
    AdminTrailersComponent,
    AdminCustomItemsComponent,
    AdminYardCraneCrewComponent,
    Shipping3plBolComponent,
    Shipping3plS3plComponent,
    Shipping3plReportingComponent,
    ShippingOfficeComponent,
    ShippingOfficeCheckoutComponent,
    ShippingOfficeCheckinComponent,
    ShippingDailySummaryComponent,
    ShippingSetupComponent,
    ShippingYardComponent,
    ShippingUnshipLoadComponent,
    WriteSignaturePadComponent,
    SpinnerLoaderComponent,
    UserPreferencesComponent,
    LoginComponent,
    MultipleDragDropComponent
  ],
  imports: [
    BrowserModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    OrderModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    RouterModule.forRoot([
      { path: 'counter', component: CounterComponent },
      { path: 'fetch-data', component: FetchDataComponent },
      { path: 'field-plan/:job', component: FieldPlanComponent, canActivate: [canActivateGuard] },
      { path: 'field-plan', component: FieldPlanComponent, canActivate: [canActivateGuard] },
      { path: 'panel-location', component: PanelLocationComponent, canActivate: [canActivateGuard] },
      { path: 'shipping3pl-bol', component: Shipping3plBolComponent, canActivate: [canActivateGuard] },
      { path: 'shipping3pl-s3pl', component: Shipping3plS3plComponent, canActivate: [canActivateGuard] },
      { path: 'shipping3pl-reporting', component: Shipping3plReportingComponent, canActivate: [canActivateGuard] },
      { path: 'shipping-office', component: ShippingOfficeComponent, canActivate: [canActivateGuard] },
      { path: 'shipping-setup', component: ShippingSetupComponent, canActivate: [canActivateGuard] },
      { path: 'shipping-yard', component: ShippingYardComponent, canActivate: [canActivateGuard] },
      { path: 'shipping-unship-load', component: ShippingUnshipLoadComponent, canActivate: [canActivateGuard] },
      { path: 'admin-custom-items', component: AdminCustomItemsComponent, canActivate: [canActivateGuard] },
      { path: 'admin-holiday-schedule', component: AdminHolidayScheduleComponent, canActivate: [canActivateGuard] },
      { path: 'home-job-trailers', component: HomeJobTrailersComponent, canActivate: [canActivateGuard] },
      { path: 'admin-yard-crane-crew', component: AdminYardCraneCrewComponent, canActivate: [canActivateGuard] },
      { path: 'admin-trailers', component: AdminTrailersComponent, canActivate: [canActivateGuard] },
      { path: 'login', component: LoginComponent },
      { path: '', component: LoginComponent },
    ],
      { onSameUrlNavigation: "reload" },
    ),
    SortableModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    CollapseModule.forRoot(),
  ],
  // providers: [],
  bootstrap: [AppComponent],
  providers: [FieldPlanDataService,
    BsModalRef,
    DraggableItemService,
    OverlayContainer,
    DatePipe,
    provideHttpClient(withInterceptors([customInterceptor])),
    { provide: CDK_DRAG_CONFIG, useValue: DragConfig },
  ],
})

export class AppModule { }
