import { Component, Inject, LOCALE_ID, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { ShippingYardService } from './shipping-yard.service';
import { ShippingYard } from './shipping-yard.model';
import { environment } from 'src/environments/environment';
import { ShippingSetupService } from '../shipping-setup/shipping-setup.service';
import { CraneCrew } from '../common-data/crane-crew.model';
import { ShippingYardTrailer } from './shipping-yard-trailer.model';
import { ShippingYardLocationType } from './shipping-yard-location-type.model';
import { ShippingYardLocation } from './shipping-yard-location.model';
import { ShippingYardPanel } from './shipping-yard-panel.model';
import { formatDate } from '@angular/common';
import { ToastService, toastTypes } from '../toast/toast.service';
import { BsModalRef, BsModalService, ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from '../error-handling/error-response.model';
import { ShippingYardCustomItem } from './shipping-yard-custom-item.model';
import { AuthService } from 'src/app/authentication/auth.service';
import { ShippingYardMarkNumberReplacement } from './shipping-yard-marknumberreplacement.model';
import { LoadingModalComponent } from '../controls/loading-modal/loading-modal.component';
import { forEach } from 'lodash';

@Component({
  selector: 'app-shipping-yard',
  templateUrl: './shipping-yard.component.html',
  styleUrl: './shipping-yard.component.css'
})
export class ShippingYardComponent implements OnInit {
  @ViewChild(ModalDirective, { static: false }) modal?: ModalDirective;

  shippingYardService: ShippingYardService = inject(ShippingYardService);
  yards: ShippingYard[] = [];
  shippingSetupService: ShippingSetupService = inject(ShippingSetupService);
  craneCrews: CraneCrew[] = [];
  craneCrewId: string = '';
  yardTrailers: ShippingYardTrailer[] = [];
  yardLocationTypes: ShippingYardLocationType[] = [];
  yardLocations: ShippingYardLocation[] = [];
  isLoading: boolean = false;
  selectedYard!: ShippingYard;
  selectedPanel!: ShippingYardPanel;
  selectedCustomItem!: ShippingYardCustomItem;
  bsModalRef?: BsModalRef;
  selectedTrailerLocationId!: number;
  selectedTrailerId: number | null = null;
  selectedTrailerNumber!: string;
  selectedCarrierName!: string;
  selectedCraneCrew = null;
  selectedTrailer = null;
  selectedTrailerLocation: number | null = null;
  selectedMarkNumber: string = '';
  order: string = "job";
  reverse: boolean = true;
  authService = inject(AuthService);
  loadPanelMarkNumber: string = '';
  yardMarkNumberReplacements: ShippingYardMarkNumberReplacement[] = [];
  selectedTrailerSize!: number;
  selectedLoadingType: string = '';
  hasTrailerErrorMessage: boolean = false;
  expandedYards: ShippingYard[] = [];
  propertyName: string = '';
  timeout: any;
  hasPerformedSearch: boolean = false;
  processAllItems: boolean = false;
  showCompletelyLoaded: boolean = false;

  constructor(@Inject(LOCALE_ID) private locale: string, private toast: ToastService, private modalService: BsModalService) { }


  ngOnInit(): void {
    this.shippingSetupService.getCraneCrews(this.authService.currentUserSig()!.companyId).subscribe(result => {

      this.craneCrews = result;
      this.isLoading = false;

      this.searchYards();

    }, error => {
      this.isLoading = false;
      console.error(error)
    });
  }

  ngOnDestroy() {
    clearTimeout(this.timeout);
  }

  openModal(template: TemplateRef<void>) {
    this.bsModalRef = this.modalService.show(template);
  }

  closeModal() {
    this.yardMarkNumberReplacements = [];

    // this.selectedTrailer = null;
    // this.selectedTrailerNumber = '';
    // this.selectedMarkNumber = '';
    // this.selectedTrailerLocation = null;
    // this.selectedCarrierName = '';
    // this.selectedLoadingType = '';
    this.modal?.hide();

  }

  searchYards() {
    this.isLoading = true;
    this.craneCrewId = this.selectedCraneCrew == null ? "-1" : this.selectedCraneCrew;

    this.shippingYardService.getYards(this.authService.currentUserSig()!.companyId, Number(this.craneCrewId)).subscribe(result => {    

      this.yards = result;
      this.hasPerformedSearch = true;

      if (!this.showCompletelyLoaded)
        this.yards = this.yards.filter(y => !y.isTrailerLoaded);

      if (this.yards.length == 0) { this.isLoading = false; }

      this.yards.forEach((yard) => {

        if (this.expandedYards.length > 0) {
          let isYardExpanded = this.expandedYards.some(x => x.jobLoadId == yard.jobLoadId)
          if (isYardExpanded) {
            yard.isExpanded = true;
          }
        }

        let yardLength = yard.panels
          .filter(item => !isNaN(Number(item.length)))
          .map(item => Number(item.length));
        let maxYardLength = Math.max(...yardLength);

        yard.maxYardLength = maxYardLength === -Infinity ? -1 : maxYardLength;

        let yardWidth = yard.panels
          .filter(item => !isNaN(Number(item.width)))
          .map(item => Number(item.width));
        let maxYardWidth = Math.max(...yardWidth);

        yard.maxYardWidth = maxYardWidth === -Infinity ? -1 : maxYardWidth;

        this.isLoading = false;


      })

      if (this.propertyName != '') {
        this.reverse = !this.reverse;
        this.setOrder(this.propertyName);
      }

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchYards();
      }, 30000)

    }, error => {
      this.isLoading = false;
      console.error(error)
    });
  }

  toggleExpanded(yard: ShippingYard) {
    yard.isExpanded = !yard.isExpanded;

    if (yard.isExpanded) {
      this.expandedYards.push(yard);
    }
    else {
      this.expandedYards = this.expandedYards.filter(i => i.jobLoadId !== yard.jobLoadId);
    }
  }

  loadTrailerPanelModal(yard: ShippingYard, panel: ShippingYardPanel, allowMarkEdit: boolean = false, processAll: boolean = false) {
    this.selectedCustomItem = undefined!;
    this.processAllItems = processAll;

    this.hasTrailerErrorMessage = false;
    this.yardMarkNumberReplacements = [];
    this.selectedMarkNumber = '';


    this.selectedYard = yard;
    this.selectedPanel = panel;
    this.selectedTrailerNumber = panel.trailerId == null ? panel.trailerNumber : '';
    this.selectedCarrierName = yard.carrierName;
    this.selectedTrailerSize = yard.trailerSize;
    this.selectedLoadingType = yard.loadingType;
    this.selectedTrailerLocation = panel.trailerLocationId;

    this.selectedTrailerId = panel.trailerId == 0 ? null : panel.trailerId;

    this.loadPanelMarkNumber = panel.mark;

    if ((!processAll && (yard.isPreLoad && !panel.isTrailerLoaded) || (allowMarkEdit && !panel.isTrailerLoaded))) {

      this.shippingYardService.getYardReplacementNumbers(panel.job, panel.mark).subscribe({
        next: (result: ShippingYardMarkNumberReplacement[]) => {
          this.yardMarkNumberReplacements.push(...result);
          this.modal?.show();
          this.loadModalDropdowns();
        },
        error: (error) => {
          // Handle error cases
          console.error(error);
        }
      });
    }
    else if (processAll) {

      if (yard.isPreLoad && !yard.isTrailerLoaded) {
        this.modal?.show();
        this.loadModalDropdowns();
      }
      else {
        // if loading/unloading all panels and custom items... if the item is not the same as the yard then update
        // otherwise bypass.
        yard.panels.forEach(panel => {
          if (yard.isTrailerLoaded == panel.isTrailerLoaded) {
            this.updatePieceStatus(panel);
          }
        });

        yard.customItems.forEach(ci => {
          if (yard.isTrailerLoaded == ci.isTrailerLoaded) {
            this.updateCustomItemStatus(ci);
          }
        });

        processAll = false;

      }

    }
    else {
      this.updatePieceStatus(panel);
    }
  }

  updatePieceStatus(panel: ShippingYardPanel) {
    let loadedOnTrailerDateTime: string = '';

    panel.isTrailerLoaded = !panel.isTrailerLoaded;

    if (panel.isTrailerLoaded && panel.loadedOnTrailerDateTime == null) {
      loadedOnTrailerDateTime = formatDate(Date.now(), 'M/d/y H:mm', this.locale);
    }

    let pieceId: number;

    if (this.selectedMarkNumber.length == 0) {
      pieceId = panel.pieceId;
    }
    else {
      pieceId = this.yardMarkNumberReplacements.find(x => x.markNumber == this.selectedMarkNumber)?.piePk!;
    }

    this.shippingYardService.updateShipYardPiece(this.authService.currentUserSig()!.companyId, pieceId, this.selectedPanel.jobLoadId, this.selectedCarrierName, this.selectedTrailerSize, this.selectedLoadingType, this.selectedTrailerNumber, loadedOnTrailerDateTime).subscribe((response: ErrorResponse) => {

      this.sleep(200).then(() => {
        this.searchYards();
      });

    }, (error: HttpErrorResponse) => {
      this.toast.initiate({
        title: error.name,
        content: error.message,
        type: toastTypes.error,
      });
    });
  }

  //Save from Assign Trailer Modal
  saveLoadTrailer() {

    clearTimeout(this.timeout);

    if (!this.validateLoad()) { return; }

    let loadedOnTrailerDateTime: string = formatDate(Date.now(), 'M/d/y H:mm', this.locale);

    this.selectedYard.trailerSize = this.selectedTrailerSize;
    this.selectedYard.loadingType = this.selectedLoadingType;
    this.selectedYard.carrierName = this.selectedCarrierName;


    if (this.selectedPanel && !this.processAllItems) {
      this.selectedTrailerLocationId = Number(this.selectedTrailerLocation);
      this.selectedPanel.trailerLocationId = this.selectedTrailerLocationId;
      let selectedPanelTrailerId: string = this.selectedTrailerId == null ? '' : this.selectedTrailerId?.toString();

      if (this.selectedTrailer != null) {
        this.selectedTrailerNumber = this.yardTrailers.find(x => x.trailerId == this.selectedTrailer)?.trailerName!;
      }

      this.shippingYardService.updateShipYardLoad(this.authService.currentUserSig()!.companyId, this.selectedPanel.jobLoadId, selectedPanelTrailerId, this.selectedTrailerNumber, this.selectedPanel.trailerLocationId, this.selectedYard.carrierName, this.selectedYard.trailerSize, this.selectedYard.loadingType).subscribe((response: ErrorResponse) => {

        let pieceId: number;
        let replacementSequence: string;
        let replacementTrailerSequence: string;
        let replacementLoadNum: string;
        let replacementCrane: number;

        if (this.selectedMarkNumber.length == 0) {
          pieceId = this.selectedPanel.pieceId;
        }
        else {
          let piece: ShippingYardMarkNumberReplacement;
          piece = this.yardMarkNumberReplacements.find(x => x.markNumber == this.selectedMarkNumber)!;
          pieceId = piece?.piePk!;
          replacementSequence = piece.sequenceNumber;
          replacementTrailerSequence = piece.trailerSequence;
          replacementLoadNum = piece.loadNum;
          replacementCrane = piece.crane;
        }

        this.shippingYardService.updateShipYardPiece(this.authService.currentUserSig()!.companyId, pieceId, this.selectedPanel.jobLoadId, this.selectedCarrierName, this.selectedTrailerSize, this.selectedLoadingType, this.selectedTrailerNumber, loadedOnTrailerDateTime).subscribe((response: ErrorResponse) => {

          this.closeModal();

          this.selectedPanel.isTrailerLoaded = !this.selectedPanel.isTrailerLoaded;

          if (this.selectedMarkNumber.length > 0) {
            this.shippingYardService.updateMarkNumberReplacements(this.selectedPanel.job, this.loadPanelMarkNumber, this.selectedMarkNumber, this.selectedPanel.loadNumber, replacementLoadNum, this.selectedPanel.sequenceNumber, replacementSequence, this.selectedPanel.trailerSEQ.toString(), replacementTrailerSequence, this.selectedPanel.crane, replacementCrane).subscribe({
              next: (result) => {

              },
              error: (error) => {
                // Handle error cases
                console.error(error);
              }
            });
          }

          this.sleep(200).then(() => {
            this.searchYards();
          });

        }, (error: HttpErrorResponse) => {
          this.toast.initiate({
            title: error.error.name,
            content: error.error.message,
            type: toastTypes.error,
          });
        });
      }, (error: HttpErrorResponse) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      });
    }

    if (this.selectedCustomItem && !this.processAllItems) {
      this.updateCustomItem(loadedOnTrailerDateTime);
    }

    if (this.processAllItems) {
      this.processAllPreload(loadedOnTrailerDateTime);
    }
  }

  private processAllPreload(loadedOnTrailerDateTime: string) {
    this.selectedTrailerLocationId = Number(this.selectedTrailerLocation);
    this.selectedPanel.trailerLocationId = this.selectedTrailerLocationId;
    let selectedPanelTrailerId: string = this.selectedTrailerId == null ? '' : this.selectedTrailerId?.toString();

    if (this.selectedTrailer != null) {
      this.selectedTrailerNumber = this.yardTrailers.find(x => x.trailerId == this.selectedTrailer)?.trailerName!;
    }

    this.shippingYardService.updateShipYardLoad(this.authService.currentUserSig()!.companyId, this.selectedPanel.jobLoadId, selectedPanelTrailerId, this.selectedTrailerNumber, this.selectedPanel.trailerLocationId, this.selectedYard.carrierName, this.selectedYard.trailerSize, this.selectedYard.loadingType).subscribe((response: ErrorResponse) => {

      this.selectedYard.panels.forEach(panel => {
        if (this.selectedYard.isTrailerLoaded == panel.isTrailerLoaded) {
          this.shippingYardService.updateShipYardPiece(this.authService.currentUserSig()!.companyId, panel.pieceId, this.selectedPanel.jobLoadId, this.selectedCarrierName, this.selectedTrailerSize, this.selectedLoadingType, this.selectedTrailerNumber, loadedOnTrailerDateTime).subscribe((response: ErrorResponse) => {
          });
          next: () => { }
          error: (error: HttpErrorResponse) => {
            this.toast.initiate({
              title: error.error.name,
              content: error.error.message,
              type: toastTypes.error,
            });
          };
        }
      });

      this.selectedYard.customItems.forEach(ci => {
        if (this.selectedYard.isTrailerLoaded == ci.isTrailerLoaded) {
          this.updateCustomItemStatus(ci);
        }
      });

      this.processAllItems = false;
      this.closeModal();
      this.searchYards();
    });
  }

  private updateCustomItem(loadedOnTrailerDateTime: string) {

    this.selectedTrailerLocationId = Number(this.selectedTrailerLocation);
    this.selectedCustomItem.isTrailerLoaded = !this.selectedCustomItem.isTrailerLoaded;
    let selectedPanelTrailerId: string = this.selectedTrailerId == null ? '' : this.selectedTrailerId?.toString();

    if (this.selectedTrailer != null) {
      this.selectedTrailerNumber = this.yardTrailers.find(x => x.trailerId == this.selectedTrailer)?.trailerName!;
    }

    this.shippingYardService.updateShipYardLoad(this.authService.currentUserSig()!.companyId, this.selectedCustomItem.jobLoadId, selectedPanelTrailerId, this.selectedTrailerNumber, this.selectedTrailerLocationId, this.selectedYard.carrierName, this.selectedYard.trailerSize, this.selectedYard.loadingType).subscribe((response: ErrorResponse) => {

      this.shippingYardService.updateShipYardCustomItem(this.selectedCustomItem.jobLoadId, this.selectedCustomItem.customItemId, loadedOnTrailerDateTime).subscribe((response: ErrorResponse) => {

        this.closeModal();

        this.sleep(200).then(() => {
          this.searchYards();
        });

      }, (error: HttpErrorResponse) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      });
    }, (error: HttpErrorResponse) => {
      this.toast.initiate({
        title: error.error.name,
        content: error.error.message,
        type: toastTypes.error,
      });
    });
  }

  validateLoad(): boolean {
    if (this.selectedTrailerId == undefined && this.selectedTrailerNumber == undefined) {
      this.hasTrailerErrorMessage = true;
      return false;
    }

    this.hasTrailerErrorMessage = false;
    return true;
  }

  updateCustomItemStatus(customItem: ShippingYardCustomItem) {

    customItem.isTrailerLoaded = !customItem.isTrailerLoaded;
    let loadedOnTrailerDateTime: string = customItem.isTrailerLoaded ? formatDate(Date.now(), 'M/d/y H:mm', this.locale) : '';

    this.shippingYardService.updateShipYardCustomItem(customItem.jobLoadId, customItem.customItemId, loadedOnTrailerDateTime).subscribe((response: ErrorResponse) => {

      this.sleep(200).then(() => {
        this.searchYards();
      });

    }, (error: HttpErrorResponse) => {
      this.toast.initiate({
        title: error.error.name,
        content: error.error.message,
        type: toastTypes.error,
      });
    });
  }

  loadModalDropdowns() {
    this.shippingYardService.getYardTrailers(this.authService.currentUserSig()!.companyId, 'JOB').subscribe(result => {

      this.yardTrailers = result;

      this.yardTrailers = this.yardTrailers.sort(function (a, b) {
        return a.trailerName.localeCompare(b.trailerName, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });

      this.shippingYardService.getYardLocationTypes(this.authService.currentUserSig()!.companyId).subscribe(locationTypeResult => {
        this.yardLocationTypes = locationTypeResult;
        let locationTypeId = (this.yardLocationTypes?.find(x => x.rowLabel.toUpperCase() == "LOT"))?.locationTypeId

        this.shippingYardService.getYardLocations(this.authService.currentUserSig()!.companyId, locationTypeId!).subscribe(locationResult => {
          this.yardLocations = locationResult;

        }, error => console.error(error));

      }, error => console.error(error));

    }, error => console.error(error));

  }

  // refreshComponent() {

  //   this.searchYards();

  // }

  loadTrailerCustomModal(yard: ShippingYard, customItem: ShippingYardCustomItem, allowEdit: boolean = false) {
    this.selectedPanel = undefined!;

    this.selectedYard = yard;
    this.selectedCarrierName = yard.carrierName;
    this.selectedCustomItem = customItem;

    this.selectedTrailerId = yard.trailerId == 0 ? null : yard.trailerId;
    this.selectedTrailerNumber = this.selectedYard.trailerNumber;
    this.selectedCarrierName = yard.carrierName;
    this.selectedTrailerSize = yard.trailerSize;
    this.selectedLoadingType = yard.loadingType;
    this.selectedTrailerLocation = this.selectedYard.panels.length > 0 ? this.selectedYard.panels[0].trailerLocationId : yard.trailerLocationId;

    if ((yard.isPreLoad && !customItem.isTrailerLoaded || (allowEdit && customItem.isTrailerLoaded === false))) {
      this.modal?.show();
      this.loadModalDropdowns();
    }
    else {
      this.updateCustomItemStatus(customItem);
    }
  }

  public invalidAssignTrailer(): boolean {

    if (this.authService.currentUserSig()!.companyId == 0) { return true; }

    if (this.selectedTrailerId !== null || this.selectedPanel?.trailerId !== null || this.selectedTrailerNumber?.length !== 0) {
      return false;
    }

    return true;
  }

  setOrder(value: string) {

    switch (value) {
      case "job":

        if (this.reverse) {
          this.yards = this.yards.sort((a, b) => (a.job.localeCompare(b.job) || a.loadDescriptor.localeCompare(b.loadDescriptor)));
        }
        else if (!this.reverse) {
          this.yards = this.yards.sort((a, b) => {
            if (b.job < a.job) return 1;
            if (b.job > a.job) return -1;
            else {
              if (b.loadDescriptor > a.loadDescriptor) { return 1 };
              if (b.loadDescriptor < a.loadDescriptor) { return -1 };
              return 0
            }
          })
        }
        break;

      default:
        break;
    }

    this.propertyName = value;

    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }


  allowLoadUnloadAll() {
    return this.authService.currentUserSig()?.hasLoadAllPermission;
  }


  openSearchModal(message: string) {
    const initialState: ModalOptions = {
      initialState: {
        message: message
      }
    };
    this.bsModalRef = this.modalService.show(LoadingModalComponent, Object.assign(initialState, { class: 'modal-lg modal-dialog-centered' }));
  }

  closeSearchModal() {

    this.modalService.hide();
  }

  sleep(ms: number | undefined) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
